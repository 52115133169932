$color-blueprint-blue1       : #358ed7 !default;
$color-blueprint-dark-blue1  : #095a9a !default;
$color-blueprint-dark-blue2  : #0c5460 !default;
$color-blueprint-dark-blue3  : #084853 !default;
$color-blueprint-light-blue1 : #eaf3fb !default;
$color-blueprint-light-blue2 : #ddeaf5 !default;
$color-blueprint-yellow1     : #ec8918 !default;
$color-blueprint-yellow2     : #f7a939 !default;
$color-blueprint-light-gray1 : #7f98ab !default;
$color-blueprint-light-gray2 : #d8dce6 !default;
$color-blueprint-light-gray3 : #bdc5cc !default;
$color-blueprint-light-gray4 : #e2e5ed !default;
$color-blueprint-light-gray5 : #f5f5f5 !default;
$color-blueprint-light-gray6 : #d3d5dc !default;
$color-blueprint-dark-gray1  : #6b6c6f !default;
$color-blueprint-dark-gray2  : #444 !default;
$color-blueprint-dark-gray3  : #666666 !default;
$color-blueprint-dark-gray4  : #3e3f42 !default;
$color-blueprint-green1      : #3bad4b !default;