@import 'header/topbar';
@import 'header/middlebar';
@import 'header/bottombar';
@import 'header/navigation';

.logo-default {
    display: inline-block;
}

@include max-screen($screen__m) {
    // There are no nav-sections on mobile so we set margin on the header instead
    .page-header {
        margin-bottom: 20px;
    }
}
