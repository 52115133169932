.pages {
    @include lib-pager();

    .page,
    .action {
        align-items: center;
        height: 37px;
    }
}

@include max-screen($screen__s) {
    .pages {
        .items {
            justify-content: center;
        }

        .pages-item-previous,
        .pages-item-next {
            width: 100%;
            justify-content: center;
        }

        .pages-item-previous {
            margin-bottom: 10px;
        }

        .pages-item-next {
            margin-top: 10px;
        }
    }
}
