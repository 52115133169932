//.filter-options-content {
//    display: none;
//}

.navigation-overlay {
    position: absolute;
    top: -78px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: .4;
    z-index: 1000000;

    > i {
        font-size: 3rem;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.filter-options-item {
    border-bottom: 1px solid $border-color__base;
}

.filter-options-title {
    padding: 20px 30px;
    background-color: $color-white;
}

.filter-options-content {
    padding: 1px 30px 30px;
    background-color: $color-white;
}

//.filter-options-content li.item {
//    position: relative;
//}

//.filter-options-content a {
//    margin-left: 20px;
//}

//.m-navigation-filter-item {
//    display: block;
//    padding-top: 2px;
//    padding-bottom: 2px;
//    margin-top: -3px;
//    margin-bottom: -3px;
//    position: relative;
//    margin-left: 0 !important;
//}

.arrowHolder {
    width: 25px;
    height: 25px;
    position: relative;
    float: left;
    z-index: 100;
    top: 0px;
}

.arrowHolder .arrowDown {
    border-color: transparent #494949 transparent transparent;
    content: "";
    border-style: solid;
    border-width: 5px;
    height: 0px;
    width: 0px;
    position: absolute;
    top: 8px;
    z-index: 1;
}

.arrowHolder .arrowLeft {
    border-color: #494949 transparent transparent transparent;
    content: "";
    border-style: solid;
    border-width: 5px;
    height: 0;
    width: 0;
    position: absolute;
    top: 9px;
    left: 5px;
    z-index: 1;
}

//.m-navigation-filter-item-ensure {
//    display: none;
//    font-size: 10px;
//    color: #006bb4 !important;
//    position: absolute;
//    right: 0;
//    top: 0;
//}

.ensure_show {
    display: block;
}

.ensure_show:hover {
    background-color: transparent !important;
    text-decoration: underline !important;
}

//.m-navigation-filter-item-nested {
//    display: none;
//}
//
//#layered-filter-block .filter-content .filter-options .filter-options-item .filter-options-content .items .item a:before {
//    content: unset !important;
//}
