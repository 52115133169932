.mst-nav__horizontal-bar {
    strong[role=heading] {
        display: none;
    }

    .apply-all-filters {
        display: none;
    }

    .filter-options {
        display: flex !important;
        border-bottom: 1px solid #ccc;

        .filter-options-item {
            border-bottom: 0;

            .filter-options-title {
                user-select: none;
            }

            .filter-options-content {
                border: 1px solid #ccc;
                background-color: #fff;
                position: absolute !important;
                z-index: 10000;
                display: none;
                max-width: 23rem;
            }
        }
    }

    .block-actions {
        display: none;
    }

    .filter-current {
        strong[role=heading] {
            display: block;
        }

        .items {
            padding: 0;

            .mst-nav__state-filter {
                white-space: nowrap;
                padding-left: 1rem;
                display: inline-block;

                .action.remove {
                    position: initial;
                }
            }

            .filter-actions {
                margin: 1rem 0;
                display: inline-block;
            }
        }
    }
}
