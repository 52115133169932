.category-navigation {
    ul {
        @include lib-list-reset-styles();
    }

    li:not(:last-of-type) {
        margin-bottom: 5px;
    }

    li:last-of-type {
        margin-bottom: 0;
    }

    a {
        @include lib-link(
            $_link-color: $text__color,
            $_link-color-visited: $text__color,
            $_link-color-hover: $text__color__hover,
            $_link-color-active: $text__color__hover
        );
        display: inline-block;
        line-height: 1.1;
        font-size: 14px;
        word-break: break-word;
        hyphens: auto;
    }
}

.home-category-list {
    margin-bottom: 15px;
}

.category-list,
#store\.menu {
    &__show-more {
        display: none;
    }

    &__show-more-anchor {
        @include lib-button-as-link();
        width: 100%;
        text-align: center;
        text-decoration: underline;
    }

    li {
        display: flex;
    }

    a {
        position: relative;
        display: inline-flex;
        align-items: center;
    }

    .img-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 34px;
        width: 40px;
        min-height: 1px;
        margin-right: 15px;
    }

    .text-wrapper {
        flex: 1;
    }

}

@include min-screen($screen__m) {
    .category-list {
        @include lib-block($padding: 25px 21px);
    }
}
