//
//  Variables
//  _____________________________________________

$collapsible-nav-color: $brand__secondary__color;
$collapsible-nav-current-border: 3px solid transparent;
$collapsible-nav-current-border-radius: 5px;
$collapsible-nav-current-border-color: $active__color;
$collapsible-nav-current-color: $brand__primary__color;
$collapsible-nav-current-font-weight: $font-weight__bold;
$collapsible-nav-delimiter__border-color: $color-gray82;
$collapsible-nav-item-hover: $menu__item__hover;

//
//  Collapsible navigation
//  -----------------------------------------

.block-collapsible-nav {
    .content {
        @include lib-block(
            $padding: 30px
        );
    }

    .delimiter-wrapper {
        padding: 13px 0;

        .item {
            margin: 0;
        }
    }

    .item {
        position: relative;
        margin: 17px 0;
        line-height: 14px;
        word-break: break-all;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        a,
        > strong {
            @include lib-css(color, $collapsible-nav-color);
            display: block;
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        &.current {
            position: relative;

            a,
            > strong {
                @include lib-css(border-color, $collapsible-nav-current-border-color);
                @include lib-css(color, $collapsible-nav-current-color);
                @include lib-css(font-weight, $collapsible-nav-current-font-weight);

                &::before {
                    content: '';
                    position: absolute;
                    background: $brand__primary__color;
                    height: 24px;
                    width: 3px;
                    left: -30px;
                    transform: translateY(-50%);
                    top: 50%;
                }
            }

            a {
                @include lib-css(border-color, $collapsible-nav-current-border-color);
            }
        }

        .delimiter {
            &::after {
                content: '';
                position: absolute;
                height: 1px;
                width: calc(100% + 60px);
                left: -30px;
                background: $border-color__base;
            }
        }
    }
}

//
//  CMS page sidebar
//  -----------------------------------------

.sidebar-additional {
    .footer-menu {
        &__item {
            @include lib-block(
                $padding: 20px 30px 30px 30px,
                $mobile-padding: 15px
            );
            margin-bottom: 30px;
        }

        &__sub-menu-list {
            position: relative;
            margin-top: 40px;

            &::before {
                content: '';
                position: absolute;
                top: -25px;
                left: -30px;
                height: 1px;
                width: calc(100% + 60px);
                background-color: $border-color__base;
            }

            .footer-menu__top-link {
                display: none;
            }
        }

        &__sub-menu-item {
            margin: 17px 0;
            line-height: 14px;
            word-break: break-all;

            &:first-child {
                margin-top: 0;
                margin-bottom: 0;
            }

            &:nth-child(2) {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__sub-menu-link {
            @include lib-link-all(
                $_link-color: $brand__secondary__color
            );
            position: relative;

            &.active {
                @include lib-link-all(
                    $_link-color: $brand__primary__color
                );

                &::before {
                    content: '';
                    position: absolute;
                    background: $brand__primary__color;
                    height: 24px;
                    width: 3px;
                    left: -30px;
                    transform: translateY(-50%);
                    top: 50%;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block-collapsible-nav {
        .title {
            @extend .abs-visually-hidden-desktop;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .block-collapsible-nav {
        width: 100%;
        z-index: 5;

        .title {
            @extend .abs-toggling-title-mobile;
            margin-bottom: 0;
        }

        .content {
            border-bottom: $border-width__base solid $border-color__base;
            display: none;

            &.active {
                display: block;
                border-top: 1px solid $theme-border-color;
                margin-bottom: 15px;
            }
        }

        .item {
            a {
                display: inline-block;
            }
        }
    }

    .sidebar-additional {
        .footer-menu {
            &__item {
                @include mobile-footer-item();
                position: relative;
                overflow: hidden;
            }

            &__sub-menu-list {
                position: relative;
            }

            &__top-link {
                margin: 0;
            }

            .parent {
                &::after {
                    display: none;
                }
            }

            li.footer-menu__item {
                margin-bottom: 15px;
            }
        }
    }
}

@include max-screen($screen__l) {
    .block-collapsible-nav {
        .content {
            @include lib-block(
                $padding: 15px
            );
        }

        .item {
            .delimiter {
                &::after {
                    width: calc(100% + 30px);
                    left: -15px;
                }
            }

            &.current {
                a,
                > strong {
                    &::before {
                        left: -15px;

                    }
                }
            }
        }
    }

    .sidebar-additional {
        .footer-menu {
            &__sub-menu-item {
                .active {
                    &::before {
                        left: -18px;
                    }
                }
            }

            &__sub-menu-list {
                a {
                    line-height: 14px;
                }
            }

            .parent {
                &::after {
                    top: 50px;
                }
            }
        }
    }
}
