.banners-section,
.banners-section-mobile {
    img {
        box-shadow: $block-shadow;
    }
}

.banners-section {
    @extend .abs-margin-for-blocks-and-widgets;

    &__right &__item {
        &:not(:last-of-type) {
            margin-bottom: 3.133%;
        }
    }

    img {
        display: block;
    }

    p {
        margin: 0;
        font-size: 0;
        line-height: 0;
    }
}

.banners-section-mobile {
    @extend .abs-margin-for-blocks-and-widgets;

    img {
        display: block;
    }

    &__item {
        &:not(:last-of-type) {
            margin-bottom: 15px;
        }
    }
}

@include max-screen(330px) {
    .banners-section {
        display: none;
    }
}

@include min-screen(330px) {
    .banners-section-mobile {
        display: none;
    }
}

@include screen(330px, $screen__s) {
    .banners-section {
        &__left {
            margin-bottom: 15px;
        }

        &__right {
            img {
                width: 100%;
            }
        }
    }
}

@include screen($screen__s, $screen__m) {
    .banners-section {
        &__left {
            margin-bottom: 15px;

            img {
                width: 100%;
            }
        }

        &__right {
            display: flex;
        }

        &__right &__item {
            &:first-of-type {
                margin-right: 15px;
            }
        }
    }
}

@include min-screen($screen__m + 1) {
    .banners-section {
        display: flex;
        justify-content: space-between;

        &__left {
            padding-right: 15px;
        }
    }
}
