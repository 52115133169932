.columns {
    @extend .abs-margin-for-pages;
    @include make-row();
    margin-right: -#{$gutter-width / 2};
    margin-left: -#{$gutter-width / 2};
}

.column.main,
.sidebar-container {
    @include make-col-ready();
    @include make-col($total-columns);
    position: static; // For some reason this cannot be relative or .product-table will cause a hozirontal scrollbar
    padding-right: #{$gutter-width / 2};
    padding-left: #{$gutter-width / 2};
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .navigation,
    .breadcrumbs,
    .page-header .header.panel,
    .footer.content,
    .page-main,
    .page-wrapper > .widget,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .top-container,
    .footer-bottom__inner {
        padding-left: $layout-indent__width;
        padding-right: $layout-indent__width;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .navigation,
    .navigation-menu__sub-menu,
    .breadcrumbs,
    .footer.content,
    .page-wrapper > .widget,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .top-container,
    .page-main,
    .footer-bottom__inner {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: $layout__max-width;
        padding-left: $layout-indent__width;
        padding-right: $layout-indent__width;
        width: auto;
    }

    .page-main {
        width: 100%;

        // These flex properties should be combined to `flex: 1 0 auto`
        // sass-lint:disable no-css-comments
        /* stylelint-disable declaration-block-no-redundant-longhand-properties */
        @include lib-vendor-prefix-flex-grow(1);
        @include lib-vendor-prefix-flex-shrink(0);
        @include lib-vendor-prefix-flex-basis(auto);
        /* stylelint-enable declaration-block-no-redundant-longhand-properties */
        // sass-lint:enable  no-css-comments

        .ie9 & {
            width: auto;
        }
    }

    .column.main {
        @include lib-layout-columns__main();
        min-height: 300px;
    }

    .sidebar-container {
        @include lib-layout-sidebar();
    }

    .panel.header {
        padding: 10px 20px;
    }
}
