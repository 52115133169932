.blog-list {
    &__title {
        @include lib-heading-as-link(h3);
    }

    &__item {
        margin-bottom: 25px;
    }

    &__image {
        height: 0;
        margin-bottom: 15px;
        padding-bottom: 62%;
        background-position: center;
        background-size: cover;
    }
}

.blog-list-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
}

@include min-screen($screen__m) {
    .blog-list {
        &__row {
            @include make-row();
            margin-bottom: -25px;
        }

        &__item {
            @include make-col-ready();
            @include make-col(8);
        }

        &__title-anchor {
            @include text-truncate(20px);
        }
    }
}
