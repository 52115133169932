.product-order-list {
    &__search-container {
        @include lib-form-input-text-xl();
        @include lib-icon-font(
            $_icon-font-content: $icon-search,
            $_icon-font-display: block,
            $_icon-font-color: $theme-icon-color-light,
            $_icon-font-size: 19px
        );
        position: relative;
        margin: 0 0 40px;
        max-width: 450px;

        input[type=text] {
            padding-left: 51px;
        }

        &::before {
            position: absolute;
            top: 7px;
            left: 16px;
        }
    }

    &__search-results {
        @extend .abs-search-results;
        max-width: 450px;
    }

    &__search-item {
        padding: 10px 15px;
        cursor: pointer;
    }

    &__product {
        width: 100%;
    }

    &__qty {
        .qty-select {
            margin-top: 0;
        }
    }

    &__product-title {
        @extend .abs-product-link;
        @include text-truncate(17px, $fixed-height: false);
    }

    &__remove {
        @include lib-button-reset();
        @include lib-icon-text-hide();
        @include lib-icon-font(
            $_icon-font-content: $icon-remove
        );
    }

    &__image {
        max-width: none;
    }

    &__item {
        border-bottom: 1px solid $border-color__base;

        &:first-child {
            border-top: 1px solid $border-color__base;
        }
    }

    &__subtotal {
        @extend .abs-price-md;
    }

    &__foot td {
        padding-top: 40px;
        padding-bottom: 0;
    }

    td {
        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    table {
        @extend .abs-margin-for-blocks-and-widgets-sidebar;

        & > tbody > tr > th,
        & > tbody > tr > td,
        & > tfoot > tr > th,
        & > tfoot > tr > td {
            vertical-align: middle;
        }
    }

    &__csv {
        input[type="file"] {
            display: flex;
            align-items: center;
            font-size: 16px;
        }

        form {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        h4 {
            border-bottom: 1px solid $border-color__base;
            padding-bottom: 10px;
        }

        a {
            font-size: 16px;
            font-weight: $font-weight__bold;

            @include lib-link(
                $_link-color                   : $text__color,
                $_link-color-visited           : $text__color,
                $_link-color-hover             : $brand__primary__color,
                $_link-color-active            : $text__color
            );
        }
    }

    &__example {
        @include lib-icon-font(
            $_icon-font-content     : $icon-download,
            $_icon-font-position    : before,
            $_icon-font-size        : 17px,
            $_icon-font-color       : $middle-bar__action__color,
            $_icon-font-color-hover : $middle-bar__action__color
        );
    }

    .btn {
        white-space: nowrap;
    }
}

.product-order-list {
    &__body &__search {
        display: none;
    }
}

@include min-screen($screen__m) {
    .product-order-list {
        &__search-item {
            display: flex;
            justify-content: space-between;
        }
    }
}

@include max-screen($screen__m) {
    .product-order-list {
        &__item {
            display: flex;
            flex-wrap: wrap;

            > * {
                box-sizing: border-box;
            }
        }

        &__image-container {
            width: 30%;
        }

        &__image {
            max-width: 100%;
        }

        &__product {
            width: 70%;
        }

        &__csv {
            input[type="file"] {
                margin-bottom: 20px;
            }

            form {
                flex-direction: column;
            }
        }
    }
}
