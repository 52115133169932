.product-table-container {
    overflow: auto;
    min-height: .01%;

    &,
    * {
        box-sizing: border-box;
    }
}

.product-table {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    margin-top: 20px;

    .tier-title {
        @include lib-heading(h3);
        font-size: 15px;
        color: $text__color__secondary;
        text-transform: capitalize;
        margin-bottom: 10px;
    }

    .stock-status {
        margin-bottom: 25px;
    }

    &__head {
        background-color: $color-white;
        border-bottom: 2px solid $border-color__base;
    }

    &__head-item {
        position: relative;
        line-height: 1.1;
        width: 1%;
    }

    &__row,
    &__collapse-inner {
        background-color: $color-white;
    }

    &__row {
        border-bottom: 1px solid $border-color__base;
    }

    &__collapse-wrapper {
        display: none;
    }

    &__collapse-inner {
        margin-bottom: 10px;
        box-shadow: $block-shadow;
    }

    &__collapse-right {
        border-top: 1px solid $border-color__base;
    }

    &__collapse-left {
        .mobile-close {
            display: none;
        }
    }

    &__collapse-left,
    &__collapse-right-inner {
        padding: 40px;
    }

    &__usps {
        padding: 30px 40px;
        background-color: $color-alcomex-grey12;

        ul {
            @extend .abs-usps;
            font-size: $font-size__base;
        }

        a {
            @include lib-link(
                $_link-color                   : $text__color,
                $_link-text-decoration         : underline,
                $_link-color-visited           : $text__color,
                $_link-text-decoration-visited : underline,
                $_link-color-hover             : $text__color,
                $_link-text-decoration-hover   : underline,
                $_link-color-active            : $text__color,
                $_link-text-decoration-active  : underline
            );
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__collapse-td {
        padding: 0;
    }

    &__show-collapse {
        @include lib-button-primary();
    }

    &__sku {
        text-decoration: underline;
        font-weight: $font-weight__bold;
    }

    &__title {
        font-size: 27px;
    }

    &__description {
        margin-bottom: $p__margin-bottom;
    }

    &__hide-collapse {
        @include lib-button-primary(
            $_button-background                  : $color-alcomex-grey11,
            $_button-background-hover            : darken($color-alcomex-grey11, 10%),
            $_button-background-active           : darken($color-alcomex-grey11, 10%),
            $_button-border                      : $color-alcomex-grey11,
            $_button-border-hover                : darken($color-alcomex-grey11, 10%),
            $_button-border-active               : darken($color-alcomex-grey11, 10%)
        );
    }

    &__button {
        @include lib-button-s();
    }

    &__hide-collapse {
        @include lib-icon-font(
            $_icon-font-content: $icon-remove,
            $_icon-font-position: after,
            $_icon-font-margin: 0 0 0 5px
        );
        display: none;
    }

    &__cart {
        @include lib-button-tertiary();
        @include lib-button-l();
        @include lib-icon-font(
            $_icon-font-content: $icon-add-to-cart,
            $_icon-font-margin: 0 20px 0 -20px,
            $_icon-font-size: 26px
        );
        margin-bottom: 10px;
    }

    &__action {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    &__column {
        padding: 10px;
        vertical-align: middle;
    }

    &__quick-order {
        @include lib-icon-font(
            $_icon-font-content: $icon-quick-order,
            $_icon-font-size: 16px,
            $_icon-font-color: $text__color__secondary
        );
        @include lib-button-s();
        @include lib-icon-text-hide();
        margin-left: 10px;
        padding: 0 11px;
    }
}

.product-table .product-add-form form {
    display: flex;
    flex-wrap: wrap;

    .price-wrapper-label {
        flex: 1;
    }

    .field.qty {
        order: -1;
        padding-right: 30px;
    }

    .list-cart-bottom {
        width: 100%;
        margin-top: 30px;
    }

    .price-box {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin-top: 18px;
    }

    .price-wrapper {
        .price {
            @include lib-heading(h1);
            margin-right: 10px;
            margin-bottom: 0;
            font-size: 30px;
            color: $brand__secondary__color;
        }
    }
}

@include min-screen($screen__l) {
    .product-table {
        &__collapse-right {
            border-left: 1px solid $border-color__base;
            border-top: 0;
        }

        &__collapse-inner {
            display: flex;
        }

        &__collapse-left {
            box-sizing: border-box;
            width: 45%;
        }

        &__collapse-right {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            width: 55%;
        }

        &__collapse-right-inner {
            flex: 1;
        }
    }
}

@include max-screen($screen__m) {
    .product-table {
        position: relative;

        .product-add-form {
            form {
                .price-wrapper {
                    .price {
                        font-size: 24px;
                    }
                }

                .field.qty {
                    padding-right: 10px;
                }
            }
        }
    }

    .product-table__collapse {
        &-left {
            position: relative;
            padding: 45px 30px 40px;

            .tier-title {
                font-size: 13px;
            }

            .tier-item {
                &__label {
                    font-size: 12px;

                    span {
                        max-width: 90px;
                        display: block;
                    }
                }

                &__price {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                }
            }

            .mobile-close {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 25px;
                height: 25px;
                align-items: center;
                justify-content: center;
                display: flex;

                .close-icon {
                    @include lib-icon-font(
                        $_icon-font-content: $icon-remove,
                        $_icon-font-color: $color-alcomex-grey1,
                        $_icon-font-size: 21px
                    );
                }
            }
        }

        &-right {
            .product-title {
                font-size: 16px;
            }

            .product-item-description {
                font-size: 12px;
            }

            .stock-status {
                font-size: 12px;
            }

            .secondary-per-piece {
                font-size: 12px;
            }

            .field.qty {
                label {
                    span {
                        font-size: 12px;
                    }
                }
            }

            &-inner {
                padding: 40px 30px;
            }

            .price-wrapper-label {
                .label {
                    font-size: 12px;
                }
            }

            .product-add-form {
                form {
                    .price-wrapper-label {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                    }
                }
            }
        }

        &-inner {
            margin: 15px 0;
        }

        &-wrapper {
            position: fixed;
            z-index: 50;
            top: 0;
            padding: 0 16px;
            height: 100vh;
            overflow-y: scroll;
            left: 0;
            right: 0;

            &:after {
                background: rgba(0, 0, 0, 0.5);
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: -1;
            }
        }
    }
}
