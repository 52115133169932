.partners-section {
    @include lib-block();

    &__title {
        white-space: nowrap;
    }
}

.partners {
    position: relative;
    min-width: 0px; // Fix for flexing parent

    &__item {
        text-align: center;
    }

    &__anchor {
        display: block;
    }
}

@include min-screen($screen__l) {
    .partners-section {
        display: flex;
        align-items: center;

        &__title {
            margin-right: 70px;
            margin-bottom: 0;
        }

        .partners {
            flex: 1;
        }
    }
}

@include max-screen($screen__m) {
    .partners-section {
        padding-bottom: 40px;
    }
}
