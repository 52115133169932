.welcome-section {
    @include lib-block(
        $padding: 10px 20px 20px,
        $mobile-padding: 10px 20px 20px
    );
    @extend .abs-margin-for-blocks-and-widgets;

    &__title {
        @include lib-heading(h2);
        margin-bottom: 20px;
        padding: 15px 0;
        text-align: center;
        border-bottom: 1px solid $border-color__base;
    }

    &__row {
        @include make-row();
    }

    &__item {
        @include make-col-ready();
        @include make-col(12);
        @include lib-icon-font(
            $_icon-font-content: false,
            $_icon-font-color: $brand__secondary__color,
            $_icon-font-size: 40px,
            $_icon-font-line-height: 62px
        );
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
        font-size: 14px;

        &::before {
            display: block;
        }

        span {
            flex: 1;
        }

        &.delivery {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-large-delivery
            );

            &::before {
                font-size: 30px;
            }
        }

        &.warehouse {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-large-warehouse
            );

            &::before {
                font-size: 44px;
            }
        }

        &.info {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-large-info
            );
        }

        &.hands {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-large-hands
            );

            &::before {
                font-size: 36px;
            }
        }
    }
}

@include min-screen($screen__m) {
    .welcome-section {
        &__content {
            padding: 0 100px;
        }
    }
}

@include min-screen($screen__l) {
    .welcome-section {
        &__content {
            padding: 0 130px;
        }

        &__item {
            @include make-col(6);
            padding-right: 30px;
            padding-left: 30px;
        }
    }
}
