.message.info {
    @include lib-message(info);
}

.message.error {
    @include lib-message(error);
}

.message.warning {
    @include lib-message(warning);
    box-shadow: $block-shadow;
}

.message.notice {
    @include lib-message(notice);
}

.message.success {
    @include lib-message(success);
}

.message {
    // For static blocks
    p {
        @include lib-css(font-size, $message__font-size);
        margin-bottom: 0;
    }
}
