$enable-grid-classes:       true !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: $screen__s,
  md: $screen__m,
  lg: $screen__l,
  xl: $screen__xl
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  md: $layout__max-width,
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                $total-columns !default;
$grid-gutter-width:           15px !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 10px !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
        (
            0: 0,
            1: $spacer,
            2: ($spacer * 2),
            3: ($spacer * 3),
            4: ($spacer * 4),
            5: ($spacer * 5),
            6: ($spacer * 6),
            7: ($spacer * 7),
            8: ($spacer * 8),
            9: ($spacer * 9)
        ),
        $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
        (
            25: 25%,
            50: 50%,
            75: 75%,
            100: 100%,
            auto: auto
        ),
        $sizes
);
