.top-categories {
    margin-bottom: 20px;
}

.badges {
    @include lib-list-reset-styles();
    display: flex;
    flex-wrap: wrap;

    &__badge {
        margin-right: 10px;
    }

    &__anchor {
        display: block;
        padding: 5px 12px;
        border-radius: 20px;
        border: 1px solid $border-color__base;
        font-weight: $font-weight__semibold;

        &:hover {
            text-decoration: none;
            border-color: darken($border-color__base, 5%);
        }
    }
}

@include max-screen($screen__m) {
    .top-categories {
        display: none;
    }
}
