.sidebar-menu {
    .menu {
        &__item {
            @include lib-block(
                $padding: 20px 30px 30px 30px,
                $mobile-padding: 15px
            );
            width: 100%;
            margin-bottom: 30px;
        }

        &__inner-list {
            @include lib-list-reset-styles();
            position: relative;
            margin-top: 40px;

            &::before {
                content: '';
                position: absolute;
                top: -25px;
                left: -30px;
                height: 1px;
                width: calc(100% + 60px);
                background-color: $border-color__base;
            }

            .menu__top-link {
                display: none;
            }
        }

        &__inner-item {
            margin: 17px 0;
            line-height: 14px;
            word-break: break-all;

            &:first-child {
                margin-top: 0;
                margin-bottom: 0;
            }

            &:nth-child(2) {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__sub-menu-link {
            @include lib-link-all(
                $_link-color: $brand__secondary__color
            );
            position: relative;

            &.active {
                @include lib-link-all(
                    $_link-color: $brand__primary__color
                );

                &::before {
                    content: '';
                    position: absolute;
                    background: $brand__primary__color;
                    height: 24px;
                    width: 3px;
                    left: -30px;
                    transform: translateY(-50%);
                    top: 50%;
                }
            }
        }

        &__top-link {
            @include lib-heading(h3);
            margin-bottom: 0;
            display: inline-block;
        }
    }
}

@include max-screen($screen__m) {
    .sidebar-menu {
        .menu {
            &__item {
                @include mobile-footer-item();
                overflow: hidden;
            }

            &__top-link {
                display: block;
            }
        }
    }
}
