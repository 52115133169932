.fotorama__dot {
    display: block;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 5px;
    height: 5px;
    background-color: $primary__color;
    border-radius: 50%;

    .fotorama__nav__frame--dot:focus & {
        box-shadow: none;

        &::after {
            border-radius: inherit;
            bottom: $fotorama-inner-box-shadow;
            box-shadow: $focus__box-shadow;
            content: '';
            left: $fotorama-inner-box-shadow;
            position: absolute;
            right: $fotorama-inner-box-shadow;
            top: $fotorama-inner-box-shadow;
            z-index: $z-index-10;
        }
    }

    .fotorama__nav__frame--thumb:focus &::after,
    .fotorama__nav__frame--dot:focus &::after {
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
    }

    .fotorama__active & {
        width: 10px;
        height: 10px;
        background-color: $brand__primary__color;
    }
}
