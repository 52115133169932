.header-bottom-bar {
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $border-color__base;
    background-color: $navigation__background;

    &__row {
        @include make-row();
        margin-right: -#{$gutter-width / 2};
        margin-left: -#{$gutter-width / 2};
    }

    &__left,
    &__right {
        @include make-col-ready();
        padding-right: #{$gutter-width / 2};
        padding-left: #{$gutter-width / 2};
    }

    &__left {
        @include make-col($layout-column__sidebar-width-m);
    }

    &__right {
        @include make-col($layout-column-main__width-2-left-m);
        display: flex;
        align-items: center;
    }

    .submenu {
        flex: 1;
    }

    .switcher {
        margin-left: 10px;
    }
}

@include max-screen($screen__m) {
    .header-bottom-bar {
        display: none;
    }
}

@include min-screen($screen__l) {
    .header-bottom-bar {
        &__left {
            @include make-col($layout-column__sidebar-width);
        }

        &__right {
            @include make-col($layout-column-main__width-2-left);
        }
    }
}
