.filter-column-parent {
    cursor: pointer;

    &:hover {
        .filter-column__asc {
            border-bottom-color: darken($icon-font__color-faded, 10%);
        }

        .filter-column__desc {
            border-top-color: darken($icon-font__color-faded, 10%);
        }
    }

    .filter-column { // placed in .filter-column-parent so it overrides the hover styles
        position: absolute;
        top: 50%;
        left: calc(100% + 4px);
        transform: translateY(-50%);

        &__asc {
            &.active {
                border-bottom-color: $text__color__secondary;
            }
        }

        &__desc {
            &.active {
                border-top-color: $text__color__secondary;
            }
        }

        &__asc {
            width: 0;
            height: 0;
            margin-bottom: 3px;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 4px solid $icon-font__color-faded;
        }

        &__desc {
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;

            border-top: 4px solid $icon-font__color-faded;
        }
    }
}

.filter-column-text {
    display: inline;
    position: relative;
}
