$product-grid-items-per-row-layout-default: 2 !default;

$product-grid-items-per-row-layout-1-screen-s: 3 !default;
$product-grid-items-per-row-layout-1-screen-m: 4 !default;
$product-grid-items-per-row-layout-1-screen-l: 5 !default;

$product-grid-items-per-row-layout-2-left-screen-s: 3 !default;
$product-grid-items-per-row-layout-2-left-screen-m: 4 !default;
$product-grid-items-per-row-layout-2-left-screen-l: '' !default;

$product-grid-items-per-row-layout-2-right-screen-s: 3 !default;
$product-grid-items-per-row-layout-2-right-screen-m: 4 !default;
$product-grid-items-per-row-layout-2-right-screen-l: '' !default;

$product-grid-items-per-row-layout-3-screen-s: 3 !default;
$product-grid-items-per-row-layout-3-screen-m: '' !default;
$product-grid-items-per-row-layout-3-screen-l: '' !default;

$product-grid-items-padding: 0 $indent__base $indent__base !default;
$product-grid-items-margin: 0 0 $indent__s !default;

$product-name-text-decoration: none !default;
$product-name-text-decoration-hover: $link__hover__text-decoration !default;

$toolbar-mode-icon-font-size: 14px !default;
$product-h1-margin-bottom-desktop: $indent__base !default;

$addto-btn-height: 56px !default;

@import 'module/listings';
@import 'module/columns';
@import 'module/toolbar';
@import 'module/gallery';
@import 'module/prices';
@import 'module/category';
@import 'module/category-list';
@import 'module/category-grid';
@import 'module/product-table';
@import 'module/category-intro';

//
//  Category view
//  ---------------------------------------------

.category-view {
    .page-title-wrapper {
        h1 {
            font-size: 30px;
        }
    }
}

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &::before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child:after {
                    content: ')';
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                @include lib-font-size(11);
            }

            &::before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

.product.name a {
    @extend .abs-product-link;
}

.category-image {
    .image {
        display: block;
        height: auto;
        max-width: 100%;
    }
}

.category-image {
    margin-bottom: $indent__s;
}

//
//  Product images general container
//  ---------------------------------------------

.product.media {
    position: relative;
    width: 100%;
}

.product-image-container {
    display: inline-block;
    max-width: 100%;
}

.product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.product-image-photo {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    margin: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

//
//  Product view
//  ---------------------------------------------

.sticky-footer-wrapper {
    display: none;
}

.product.media {
    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        @include lib-css(color, $text__color__muted);
        @include lib-font-size($font-size__s);
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

.product.info.detailed {
    clear: both;

    .product.data.items {
        margin-bottom: 0;
    }

    .additional-attributes {
        @include lib-table-resize(
            $_th-padding-left: 0,
            $_th-padding-right: $indent__l,
            $_th-padding-bottom: $indent__s,
            $_td-padding-bottom: $indent__s
        );
    }
}

.product-info-main {
    .page-title-wrapper {
        .page-title {
            margin-bottom: $indent__s;
        }
    }

    .stock {
        &.available,
        &.unavailable {
            margin-bottom: $indent__s;
            vertical-align: top;
        }
    }

    .product {
        &.attribute {
            &.sku {
                margin-bottom: $indent__s;
                @include lib-css(color, $text__color__muted);

                > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xs;
                }
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .price-box {
        .old-price,
        .normal-price .price-label {
            display: block;
        }
    }

    .product-reviews-summary .reviews-actions {
        @include lib-font-size($font-size__base);
    }
}

.product-info-summary {
    box-sizing: border-box;

    .product-title {
        margin-bottom: $indent__s;
        font-size: 27px;
    }
}

.product-options-wrapper {
    .fieldset-product-options-inner {
        .legend {
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(margin, 0 0 $indent__xs);
            @include lib-font-size(14px);
            border: none;
            display: inline-block;
            float: none;
            padding: 0;
        }

        //  Date & Time custom option (Affect Time that goes only after Date)
        input.datetime-picker {
            ~ select.datetime-picker {
                margin-top: $indent__s;
            }
        }

        &.required,
        &._required {
            .legend {
                &::after {
                    content: '*';
                    @include lib-typography(
                        $_font-size: $form-field-label-asterisk__font-size,
                        $_color: $form-field-label-asterisk__color,
                        $_font-family: $form-field-label-asterisk__font-family,
                        $_font-weight: $form-field-label-asterisk__font-weight,
                        $_line-height: $form-field-label-asterisk__line-height,
                        $_font-style: $form-field-label-asterisk__font-style
                    );
                    @include lib-css(margin, $form-field-label-asterisk__margin);
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.product-info-main {
    .product.overview {
        margin-bottom: $p__margin-bottom;
    }
}

.product-info-main,
.product-options-bottom {
    .price-box {
        .price {
            white-space: nowrap;
        }
    }

    .tier-title {
        @include lib-heading(h3);
        font-size: 15px;
        color: $text__color__secondary;
        text-transform: capitalize;
        margin-bottom: 10px;
    }

    .special-price {
        display: block;
        margin: 0 0 $indent__s;

        .price-label + .price-wrapper {
            display: inline-block;
        }
    }

    .box-tocart {
        margin: $indent__base 0 0;

        .fieldset {
            display: flex;
            flex-wrap: wrap;
        }

        .field.qty {
            padding-right: 0.75 * $indent__base;
        }

        .input-text.qty {
            height: auto;
        }

        .qty-select {
            height: 50px;
        }

        .actions {
            flex: 1;
            display: flex;
            align-items: flex-end;
            margin: 0 0 $indent__base;

            .tocart {
                width: 100%;
                height: 50px;
                white-space: nowrap;
            }
        }

        .action.tocart {
            @extend .abs-button-l;
        }
    }

    .product-addto-links {
        margin: 0 0 $indent__base;
    }

    .action.tocompare {
        @extend .abs-actions-addto;
        vertical-align: top;
    }
}

.prices-tier {
    @extend .abs-reset-list;
    margin-top: 10px;

    &.in-limit {
        margin-bottom: 25px;
    }

    .secondary-per-piece {
        display: none;
    }

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;

        .price {
            @include lib-font-size(14);
            font-weight: $font-weight__bold;
        }
    }

    .tier-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $border-color__base;
        padding: 10px 24px 10px 0;

        &__add {
            position: relative;

            .btn--alternative {
                height: 28px;
                font-weight: $font-weight__light;
                font-size: 13px;
                padding: 0 11px;
                position: relative;
                white-space: nowrap;

                &::after {
                    content:'+';
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 28px;
                    width: 28px;
                    color: $color-white;
                    background: $brand__primary__color;
                    position: absolute;
                    right: -25px;
                    top: -1px;
                    border-radius: 0 3px 3px 0;
                    font-size: 15px;
                }
            }


        }

        .price-label-wrapper {
            display: flex;
            flex: 1;
        }

        &__label {
            flex: 1;
        }

        &__price {
            margin-right: 20px;
            white-space: nowrap;

            .price-container {
                font-weight: $font-weight__bold;
                color: $brand__secondary__color;
                margin-right: 5px;
            }
        }

        &:first-child {
            border-top: 1px solid $border-color__base;
        }
    }
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.block.related {
    .action.select {
        margin: 0 $indent__xs;
    }
}

.product-usps {
    @extend .abs-usps;
    margin-top: 20px;
    padding-top: $indent__base;
    position: relative;

    li {
        color: $text__color;
    }

    a {
        @include lib-link(
            $_link-color                   : $text__color,
            $_link-text-decoration         : underline,
            $_link-color-visited           : $text__color,
            $_link-text-decoration-visited : underline,
            $_link-color-hover             : $text__color__hover,
            $_link-text-decoration-hover   : underline,
            $_link-color-active            : $text__color__hover,
            $_link-text-decoration-active  : underline
        );
    }

    &::before {
        content: '';
        height: 1px;
        width: calc(100% + 80px);
        left: -40px;
        top: 0;
        background: $border-color__base;
        position: absolute;
    }
}

.category-seo-text {
    @extend .abs-margin-for-blocks-and-widgets;
    @extend .std;
    @include lib-block(
        $padding: 40px 40px (40px - $p__margin-bottom) 40px
    );
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            position: relative;
        }

        .product-item:not(:last-of-type) {
            margin-bottom: $indent__base;
        }

        .product-item-info {
            position: relative;
            width: auto;

            .product-item-photo {
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        .product-item-name {
            margin-top: 0;
        }

        .product-item-details {
            margin: 0 0 0 85px;
        }

        .product-item-actions {
            display: block;
            margin-top: $indent__s;
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            @include lib-css(color, $primary__color__lighter);
            @include lib-font-size(12);
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

.attribute-group {
    &__title {
        margin-bottom: 10px;
    }
}

.catalog-product-view {
    .page-sub-title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: $indent__base;
    }

    .table.additional-attributes {
        @include lib-table-resize(
            $_th-padding-top: 0,
            $_th-padding-left: 0,
            $_th-padding-right: 0,
            $_th-padding-bottom: 0,
            $_td-padding-top: 0,
            $_td-padding-left: 0,
            $_td-padding-right: 0,
            $_td-padding-bottom: 0
        );

        tbody {
            line-height: 24px;
        }

        th,
        td {
            width: 50%;
        }

        th {
            font-weight: $font-weight__regular;
        }

    }

    .attribute-groups {
        margin-bottom: 40px;

        .title {
            margin-bottom: 40px;
        }
    }

    .action.towishlist {
        @include lib-icon-font-size(
            $_icon-font-size: 20px
        );

        &::before {
            margin: -2px 10px 0 0;
            color: $theme-icon-color-light;
        }

        &:hover {
            &::before {
                color: darken($theme-icon-color-light, 5%);
            }
        }
    }

    .inscription {
        display: flex;
        flex-direction: column;
    }
}

.stock {
    &.available {
        color: $accept__color;
    }

    &.unavailable {
        color: $error__color;
    }
}

.special-price {
    .price-label {
        display: none;
    }
}

.qty-increments-notice {
    @include lib-message(info);
}

.block-description {
    margin: 0;

    .description {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.category-support {
    @include lib-block();

    &__buttons {
        display: flex;
        flex-wrap: wrap;
    }

    &__button {
        @include lib-link-as-button();
        @include lib-button-alternative();
        margin-bottom: 10px;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}

.product-add-form {
    .qty-select {
        margin-bottom: 15px;
    }
}

@include max-screen($screen__l) {
    .category-support {
        &__button {
            width: 100%;
            justify-content: center;

            &:not(:last-child) {
                margin-right: 0;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .sticky-footer-wrapper {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 70px;
        background-color: $color-white;
        z-index: 20;

        .sticky-footer {
            form {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 0 10px;

                .qty-field-wrapper {
                    display: flex;
                    flex-direction: row;

                    .field.qty {
                        order: 1;

                        .qty-select {
                            .qty-select__input {
                                width: 40px;
                            }
                        }
                    }

                    .price-wrapper-label {
                        margin: 7px;
                        order: 2;
                        font-size: $font-size__xl;
                        font-weight: $font-weight__bold;
                        color: $color-alcomex-dark-blue;

                        .price-box {
                            margin-top: 5px;

                            .secondary-per-piece {
                                white-space: nowrap;
                            }
                        }
                    }
                }

                .btn--tertiary {
                    margin: 10px;
                    order: 3;
                    padding: 0 5px;
                }
            }
        }
    }

    .product-info-columns {
        &__item {
            margin-left: -$layout-indent__width;
            margin-right: -$layout-indent__width;

            .block:not(.block-description):not(.review-add):not(.attribute-groups),
            .product.description {
                margin-bottom: 20px;
                padding-bottom: 20px;
                padding-left: $layout-indent__width;
                padding-right: $layout-indent__width;
                border-bottom: 1px solid $border-color__base;
            }

            .product.description {
                padding-bottom: 0;
            }

            .block.review-add {
                margin-bottom: 0;
            }

            .block.attribute-groups {
                margin-bottom: 20px;
                padding-left: $layout-indent__width;
                padding-right: $layout-indent__width;
            }
        }
    }

    .product-info-detail-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .product-info-summary {
        order: 1;
    }

    .product.media {
        order: 2;
    }

    .product-info-main {
        order: 3;
    }

    .product.info.detailed {
        .additional-attributes {
            * {
                display: block;
            }

            tbody > tr > th {
                padding-bottom: 0;
            }

            tbody > tr > td {
                padding-left: 0;
            }
        }
    }

    .catalog-product-view {
        .column.main {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-direction(column);
        }

        .attribute-groups {
            margin-bottom: 20px;

            .title {
                margin-bottom: 10px;
            }
        }

        .product.media {
            order: 2;
        }
    }

    .product-info-main .box-tocart {
        .actions {
            .action.tocart {
                @extend .abs-button-responsive-smaller;
            }
        }
    }

    .block.related {
        .action.select {
            display: block;
            margin: $indent__xs 0;
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare,
    [class*='block-compare'] {
        display: none;
    }
}

@include screen($screen__m, ($screen__l - 1)) {
    .catalog-category-view {
        &.page-layout-2columns-left {
            .column.main {
                @include make-col($total-columns);
            }
        }
    }
}

@include max-screen($screen__l) {
    .catalog-category-view {
        &.page-layout-2columns-left {
            .sidebar-additional {
                display: none;
            }

            .block.filter {
                margin-bottom: 0;
            }
        }
    }

    .product-usps {
        &::before {
            left: -20px;
            width: calc(100% + 40px);
        }
    }

    .column {
        .product-info-summary-bottom {
            padding: 20px;

            .price-wrapper-label {
                margin-left: 0;
            }

            .button-wrapper {
                .tocart {
                    padding: 0 20px;

                    &:before {
                        font-size: 18px;
                    }
                }

                .btn--alternative {
                    padding: 0 10px;

                    &:before {
                        font-size: 17px;
                    }
                }
            }
        }
    }

    .product-info-summary-bottom {
        .tocart,
        .btn--alternative {
            width: 100%;
        }

        .tocart {
            margin-bottom: 20px;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product-info-summary {
        padding-left: 15px;
    }

    .tier-item {
        &__label {
            padding-right: 30px;
        }
    }

    .product-info-bottom {
        clear: both;
    }

    .product.media {
        padding-right: 15px;
    }

    .catalog-product-view {
        .product.attribute.description {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }

    .product-description {
        display: flex;
        width: 100%;
    }

    .product.info.detailed {
        .additional-attributes {
            table-layout: fixed;

            tbody > tr > th {
                position: relative;
                width: 190px;
                padding-right: 20px;
                font-weight: $font-weight__regular;

                &::after {
                    content: ':';
                    position: absolute;
                    right: 5px;
                }
            }
        }
    }

    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: left;
                    left: auto;
                    margin: 0 $indent__s $indent__s 0;
                    position: relative;
                    top: auto;
                }
            }

            .product-item-details {
                margin: 0;
            }

            .product-item-actions {
                clear: left;
            }
        }
    }

    .product-add-form {
        @extend .abs-revert-field-type-desktop;
    }
}

.tier-wrapper {
    li.hide {
        display: none;
    }

    .show-more {
        font-size: 14px;
        display: inline-block;
    }

    .btn-show-more,
    .btn-show-less {
        @include lib-link-all(
            $_link-color: $brand__primary__color
        );
        padding: 14px 0;

        .product-info-summary & {
            padding: 14px 0 25px;
        }

        &:hover {
            cursor: pointer;
            text-decoration: none;
        }

        span {
            text-decoration: underline;
        }
    }

    .btn-show-more {
        @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-size: 12px,
            $_icon-font-position: after
        );
    }

    .btn-show-less {
        @include lib-icon-font(
            $_icon-font-content: $icon-up,
            $_icon-font-size: 12px,
            $_icon-font-position: after
        );
    }

    .no-display {
        display: none;
    }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m) {
    .product.media {
        float: left;
    }

    .product-info-summary,
    .product-info-main {
        float: right;
    }

    .product-info-bottom {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: $layout-indent__width / 2;
            left: $layout-indent__width / 2;
            border-top: 1px solid $border-color__base;
        }
    }

    .product-info-main,
    .product.media {
        box-sizing: border-box;
    }

    .product.media {
        margin-bottom: 30px;
    }


    .product-info-wrapper {
        display: flex;
        justify-content: space-between;

        &__left {
            display: flex;
            width: (100% / 3);
        }

        &__right {
            display: flex;
            flex-direction: column;
            width: calc(100% / 3 * 2 - 30px);
        }

    }

    .product-info-summary {
        display: flex;
        flex-direction: column;
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__l) {
    .filter {
        &-subtitle {
            @include lib-heading(h3);
            display: block;
            font-weight: $font-weight__bold;
            color: $text__color__secondary;
            margin: 0;
            padding: 20px 30px;
            word-break: break-all;
            border-bottom: 1px solid $border-color__base;
            background-color: $color-white;
        }
    }

    .product-info-columns {
        @include make-row();

        &__item {
            @include make-col-ready();
            @include make-col(12);

            &--left {
                padding-right: 44px;
            }

            &--right {
                padding-left: 44px;
            }
        }
    }

    .product-info-main,
    .product-options-bottom {
        .box-tocart {
            .action.tocart {
                @include lib-icon-font(
                    $_icon-font-content: $icon-cart,
                    $_icon-font-size: 18px,
                    $_icon-font-margin: 0 10px 0 0,
                    $_icon-font-vertical-align: top
                );
            }
        }
    }

    .product-info-summary {
        padding-left: 40px;
    }

    .product.media {
        padding-right: 30px;
    }

    .category-image {
        margin-bottom: $indent__l;
    }
}

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: none;
                    left: 0;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }

            .product-item-details {
                margin-left: 85px;
            }
        }
    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: 15px 0;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}

.table-comparison {
    table-layout: fixed;

    .cell.label.remove,
    .cell.label.product {
        span {
            @extend .abs-visually-hidden;
        }
    }

    .cell.label,
    td:last-child {
        border-right: $table__border-width $table__border-style $table__border-color;
    }

    .cell {
        padding: 15px;
        width: 140px;

        .attribute.value {
            overflow: hidden;
            width: 100%;
        }

        &.product.info,
        &.product.label {
            border-bottom: $table__border-width $table__border-style $table__border-color;
        }

        &.label {
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
            }
        }

        &.attribute {
            @include lib-font-size(13);

            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
        margin: 15px 0;
    }

    .product-addto-links {
        margin-top: 15px;

        .action.split,
        .action.toggle {
            @include lib-button-s();
        }

        .action.toggle {
            padding: 0;
        }
    }

    .cell.remove {
        padding-bottom: 0;
        padding-top: 0;
        text-align: right;

        .action.delete {
            @extend .abs-remove-button-for-blocks;
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison.headings {
    @include lib-css(background, $page__background-color);
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}

.block-compare {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item .product-item-name {
        margin-left: 22px;
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            left: 0;
            position: absolute;
            top: 0;
        }

        &.compare {
            @extend .abs-revert-secondary-color;
        }
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .actions-toolbar {
        margin: 17px 0 0;
    }
}

.category-description-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.category-description {
    max-height: none;
    position: relative;
    line-height: 1.57;

    &:not(.collapsed) {
        .toggle-description {
            margin-top: -15px;
        }
    }

    &.collapsed {
        display: inline-block;
        display: -webkit-box;
        flex: 1;
        max-height: 3em;
        margin-right: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
}

.product-info-summary-bottom {
    @include lib-block();
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px 40px;
    float: right;
    background: $white-bg__hover__color;
    margin-bottom: 30px;

    form {
        display: flex;
        flex-wrap: wrap;
    }

    label {
        font-size: 12px;
    }

    .box-tocart {
        width: 100%;

        .button-wrapper {
            margin-top: 5px;
        }

        .tocart {
            @include lib-icon-font(
                $_icon-font-content: $icon-add-to-cart,
                $_icon-font-size: 26px,
                $_icon-font-margin: 0 10px 0 0
            );
            height: $addto-btn-height;
            padding: 0 65px;
            margin-right: 10px;
        }

        .btn--alternative {
            @include lib-icon-font(
                $_icon-font-content: $icon-quick-order,
                $_icon-font-size: 17px,
                $_icon-font-margin: 0 10px 0 0
            );
            height: $addto-btn-height;
            color: $text__color;
        }

    }

    .price-wrapper-label {
        margin-left: 30px;
    }

    .price-wrapper {
        .price {
            @include lib-heading(h1);
            font-size: 30px;
            color: $brand__secondary__color;
        }
    }
}

.toggle-description {
    @include lib-css(margin-bottom, lib-font-size-value($p__margin-bottom));
    align-self: flex-end;
    margin-top: -15px;
    display: inline-block;
    white-space: nowrap;
}

.secondary-per-piece {
    font-size: 14px;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .product-info-wrapper {
        display: flex;
        flex-direction: column;

        &__right {
            order: 1;
        }

        &__left {
            order: 2;
        }
    }

    .category-description-wrapper {
        flex-direction: column;

        p {
            margin: 0;
        }
    }

    .product-info-detail-wrapper {
        flex-direction: column-reverse;
    }

    .toggle-description {
        align-self: flex-start;
        margin: 10px 0;
    }

    .tier-item {
        justify-content: space-between;
    }

    .product-info-summary-bottom {
        width: 100%;
        margin-left: 0;
    }

    .button-wrapper {
        .actions {
            display: flex;
        }

        button {
            width: 50%;
        }
    }
}

@include max-screen($screen__s) {
    .button-wrapper {
        .actions {
            flex-direction: column;
        }

        button {
            width: 100%;
        }
    }
}
