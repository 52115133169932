.interactive-banner-container {
    margin-bottom: 16px;
}

.interactive-banner {
    position: relative;

    &__image {
        display: block;
    }

    &__points {
        @include lib-list-reset-styles();
    }

    &__point {
        position: absolute;
        z-index: $z-index__interactive-banner;
        margin: 0;
        transform: translateX(-50%) translateY(-50%);

        &--is-open {
            z-index: $z-index__interactive-banner-active;
        }
    }
}

.interactive-banner-inner {
    position: absolute;
    top: 50%;
    display: flex;
    width: 37vw;
    max-width: 400px;
    transform: translateY(-50%);
    z-index: $z-index__interactive-banner;
    visibility: hidden;
    opacity: 0;
    padding: 15px;
    background-color: #fff;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.3);
    transition: opacity 0.2s 0s,
    visibility 0s 0.2s,
    transform 0.2s 0s;

    .interactive-banner__point--is-open & {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.2s 0s,
        visibility 0s 0s,
        transform 0.2s 0s;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
    }

    &--left {
        right: calc(100% + 45px);

        .interactive-banner__point--is-open & {
            transform: translateY(-50%) translateX(15px);
        }

        // Shadow for the triangle
        &::before {
            content: '';
            position: absolute;
            left: calc(100% - 9px);
            top: calc(50% - 9px);
            width: 18px;
            height: 18px;
            transform: rotate(45deg);
            transform-origin: 50%;
            box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.3);
        }

        &::after {
            left: 100%;
            border-left: 15px solid $color-white;
        }
    }

    &__right {
        // To cover the shadow for the triangle
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 15px;
            background-color: $color-white;
        }
    }

    &--right {
        left: calc(100% + 45px);

        &::after {
            right: 100%;
            border-right: 15px solid $color-white;
        }

        .interactive-banner__point--is-open & {
            transform: translateY(-50%) translateX(-15px);
        }
    }

    &__image-anchor {
        display: block;
        margin-right: 15px;
    }

    &__image {
        display: block;
    }

    &__title {
        margin-top: 0;
        margin-bottom: 10px;
        padding-right: 20px;
        font-weight: $font-weight__regular;
        font-size: 17px;
    }

    &__price {
        @extend .abs-price-md;
        margin-bottom: 10px;
        line-height: 1;
    }

    &__anchor {
        @extend .abs-cta-link;
    }

    &__close {
        @include lib-icon-text-hide();
        @include lib-icon-font(
            $_icon-font-content: $icon-remove,
            $_icon-font-color: $text__color,
            $_icon-font-size: 18px
        );
        position: absolute;
        top: 15px;
        right: 15px;
    }
}

@include max-screen($screen__s) {
    .interactive-banner-container {
        display: none;
    }
}

@include min-screen($screen__l) {
    .interactive-banner-inner {
        width: 40vw;
        max-width: 340px;
    }
}
