.navigation-menu {
    &__list,
    &__sub-menu-row,
    &__sub-menu-list {
        @include lib-list-reset-styles();
    }

    &__sub-menu-top-link {
        @include lib-link-all(
            $_link-color: $text__color
        );
        font-weight: $font-weight__medium;
    }
}

.menu {
    &__list {
        @include lib-list-reset-styles();
        display: flex;

        a {
            @include lib-link(
                $_link-color: $text__color__secondary,
                $_link-color-visited: $text__color__secondary,
                $_link-color-hover: $text__color__secondary__hover,
                $_link-color-active: $text__color__secondary__hover
            );
        }
    }
}

.submenu-toggle {
    display: none;
}

.navigation-menu {
    &__list {
        li:not(:last-of-type) {
            margin-bottom: 13px;
        }

        .navigation-menu__sub-menu {
            li {
                display: flex;
                align-items: center;
                min-height: 26px;
                max-width: 100%;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            &-container {
                border-radius: 0 3px 3px 0;
            }
        }

        li:first-of-type {
            margin-top: 3px;
        }
    }
}

.home-fixed-menu {
    .navigation-menu {
        .menu-item-wrapper {
            display: flex;
            flex: 1;
            padding-right: 10px;

            .menu-category-image {
                max-width: 40px;
                margin-right: 15px;
            }
        }

        &__sub-menu {
            padding: 0;

            h1 {
                margin-bottom: 18px;
            }

            &-container {
                padding: 25px;
            }
        }

        &__sub-menu-row {
            flex-direction: column;
        }

        &__sub-menu-column {
            max-width: none;
            padding: 0;
            margin-top: 0;
        }

        &__item {
            &.parent {
                @include lib-icon-font(
                    $_icon-font-content: $icon-up,
                    $_icon-font-size: 17px,
                    $_icon-font-position: after,
                    $_icon-font-display: flex,
                    $_icon-font-color: $brand__primary__color
                );

                &:after {
                    position: absolute;
                    right: 0;
                    font-weight: 100;
                    transform: rotate(90deg);
                }
            }
        }

        &__sub-menu-container {
            position: absolute;
            box-sizing: border-box;
            border: 0;
        }
    }
}

.mobile-menu {
    .navigation-menu {
        &__list {
            li:not(:last-of-type) {
                max-height: none;
            }
        }

        &__item {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid $theme-border-color;
            margin-bottom: 0;

            &.parent {
                .submenu-toggle {
                    display: flex;
                    position: absolute;
                    align-items: center;
                    justify-content: center;
                    right: 15px;
                    width: 20px;
                    height: 20px;

                    @include lib-icon-font(
                        $_icon-font-content: $icon-expand,
                        $_icon-font-size: 16px,
                        $_icon-font-position: after,
                        $_icon-font-display: flex,
                        $_icon-font-color: $brand__primary__color
                    );
                }

                .navigation-menu__sub-menu {
                    .submenu-toggle {
                        display: none;
                    }

                    h1 {
                        display: none;
                    }

                    li:not(:last-of-type) {
                        margin-bottom: 0;
                    }

                    li {
                        margin-left: 5px;
                    }
                }
            }

            .submenu-toggle {
                flex: 1;
                display: flex;
            }

            img {
                margin-left: 8px;
                width: 40px;
            }

            .menu-item-wrapper {
                display: flex;
                align-items: center;

                &.active {
                    > .submenu-toggle:after {
                        content: '\e60f';
                    }
                }
            }

            &:not(:last-of-type) {
                margin-bottom: 0;
            }

            a {
                border-bottom: 0;
                padding: 19px 11px;
            }
        }
    }
}

.main-menu {
    .navigation-menu {
        .menu-item-wrapper {
            display: flex;
            flex: 1;
            align-items: center;
        }

        &-container {
            position: static;
        }

        &__sub-menu {
            padding: 0;

            h1 {
                margin: 3px 0 30px;
            }
        }

        &__sub-menu-column {
            margin-bottom: 15px;
        }

        &__sub-menu-top-link {
            &:hover {
                color: $color-alcomex-light-blue;
            }
        }

        &__sub-menu-row {
            flex-direction: column;
        }

        &__sub-menu-container {
            left: calc(100% - 1px);
            border: 0;
            border-left: 1px solid $theme-border-color;
            padding: 25px;
            box-sizing: border-box;
        }

        &__item {
            margin-bottom: 10px;

            &:first-of-type {
                margin-top: 7px;
            }

            &.parent {
                @include lib-icon-font(
                    $_icon-font-content: $icon-up,
                    $_icon-font-size: 17px,
                    $_icon-font-position: after,
                    $_icon-font-display: flex,
                    $_icon-font-color: $brand__primary__color
                );

                &:after {
                    position: absolute;
                    right: 15px;
                    font-weight: 100;
                    transform: rotate(90deg);
                }
            }

            .menu-category-image {
                max-height: 34px;
                width: 40px;
            }
        }

        &__top-link {
            margin-left: 15px;

            &:hover {
                color: $color-alcomex-light-blue;
            }
        }
    }
}

.submenu {
    &__list {
        font-size: 14px;
        font-weight: $font-weight__bold;

        li {
            margin-right: 25px;
        }
    }
}

@include min-screen($screen__m) {
    .navigation-menu-container {
        @include lib-css(background-color, $header__navigation__background-color);
    }

    .navigation-menu {
        &-container {
            position: relative;
        }

        &__item {
            margin: 0;
        }

        &__top-link {
            @include lib-link-all(
                $_link-color: $header__navigation__top-link-color
            );
            display: inline-block;
            font-size: 14px;
            line-height: 1.3;

            &.all {
                display: none;

            }
        }

        &__sub-menu-container {
            position: absolute;
            z-index: $z-index__megamenu;
            top: 100%;
            left: 0;
            right: 0;
            width: 100%;
            padding: 30px 0 10px;
            background-color: $color-white;
            border-top: 1px solid $border-color__base;
            border-bottom: 1px solid $border-color__base;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1)
        }

        &__sub-menu-row {
            @include make-row();
        }

        &__sub-menu-column {
            @include make-col-ready();
            @include make-col(8);
            margin-bottom: 20px;
            max-width: 100%;
        }

        &__sub-menu-list {
            margin-top: 5px;
        }

        &__top-linkall {
            display: none;
        }

        &__sub-menu-item {
            display: block;
            line-height: 1.2;
            margin: 0;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
}

@include screen($screen__m, ($screen__l - 1)) {
    .submenu {
        &__list {
            font-size: 13px;

            li {
                margin-right: 15px;
            }
        }
    }
}

@include min-screen($screen__l) {
    .navigation-menu {
        &__sub-menu-column {
            @include make-col(6);
        }
    }
}

@include max-screen($screen__m) {
    .nav-sections {
        width: 100vw;
        left: -100%;

        a {
            @include lib-link-all(
                $_link-color: $text__color
            );
        }
    }

    .navigation-menu,
    .nav-sections .footer-menu {
        a {
            display: block;
            padding: 15px;
            font-size: 13px;
            border-bottom: 1px solid $color-gray82;

            &:hover {
                text-decoration: none;
            }
        }

        &__item.parent {
            position: relative;
        }

        &__item {
            margin: 0;

            &--active.parent {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-minus,
                    $_icon-font-position : after
                );
            }
        }

        &__top-linkall {
            margin-bottom: 10px;
        }
    }
}

//
//  Debugging
//  _____________________________________________

//.navigation-menu__item:first-child > div {
//    display: block !important;
//}
