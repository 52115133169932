.mst-nav__swatch {
    .swatch-option-link-layered {
        &._checked {
            .swatch-option {
                outline: 2px solid #ff5501;
                border-color: #fff;
            }
        }
    }
}
