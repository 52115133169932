.category-dropdown {
    position: relative;
    z-index: 120;

    &__button {
        @include lib-button(
            $_button-background: $button-primary__background,
            $_button-color: $button-primary__color,
            $_button-border: $button-primary__border,
            $_button-background-hover: $button-primary__hover__background,
            $_button-color-hover: $button-primary__hover__color,
            $_button-border-hover: $button-primary__hover__border,
            $_button-background-active: $button-primary__background,
            $_button-color-active: $button-primary__color,
            $_button-border-active: $button-primary__border
        );
        @include lib-button-icon(
            $_icon-font-content: $icon-down,
            $_icon-font-size: 17px
        );
        position: relative;
        width: 100%;

        .active & {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-up,
                $_icon-font-position: after
            );
            background-color: $color-white;
            color: $brand__primary__color;
            border-radius: $button__border-radius $button__border-radius 0 0;
            border-color: $color-white;
        }

        &::after {
            position: absolute;
            top: 50%;
            right: $button__padding__x;
            transform: translateY(-50%);
        }
    }

    &__dropdown {
        position: absolute;
        right: 0;
        left: 0;
        z-index: 1;
    }

    .category-list {
        border-top: 1px solid $border-color__base;
        border-radius: 0 0 $button__border-radius $button__border-radius;
    }

    &:not(.active) &__dropdown {
        display: none;
    }
}
