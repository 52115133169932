.transition-hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 200ms, opacity 200ms;
}

.transition-visible {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 200ms;
}

.category-dropdown-overlay {
    @extend .transition-visible;
    position: fixed;
    z-index: 110;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($brand__secondary__color, 0.4);

    html:not(.category-dropdown-active) & {
        @extend .transition-hidden;
    }
}

.filter-overlay {
    @extend .transition-visible;
    position: fixed;
    z-index: 140;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($brand__secondary__color, 0.4);

    html:not(.filter-active) & {
        @extend .transition-hidden;
    }
}
