$qty-select_text-color: $color-alcomex-grey2;
$qty-select_icon-color: #dcdcdc;

.promote-tier-price {
    font-size: 18px;
    color: #0c5460;
    margin: 0 40px 10px 0;

    .promotion-text {
        padding: 5px 15px;
        background-color: #ddeaf5;
    }
}

.qty-select {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: stretch;
    margin-right: 10px;
    margin-top: 10px;

    &__button {
        width: 47px;
        padding: 0;
        box-sizing: border-box;
        @include lib-button-reset();
        font-size: 1.4rem;
        line-height: 1.6rem;

        &:active {
            box-shadow: none;
        }

        &:hover {
            border: 1px solid $theme-border-color;
        }

        &--increase {
            @include lib-icon-font(
                $_icon-font-content     : $icon-expand,
                $_icon-font-size        : 17px,
                $_icon-font-text-hide   : true,
                $_icon-font-color       : $color-white
            );
            background-color: $brand__primary__color;
            border-radius: 0 3px 3px 0;

            &,
            &:hover,
            &:focus {
                border: 1px solid $brand__primary__color;
            }

            &:hover,
            &:focus {
                background-color: $brand__primary__color__dark;
                border-color: $brand__primary__color__dark;
                color: $color-white;
            }
        }

        &--decrease {
            @include lib-icon-font(
                $_icon-font-content     : $icon-minus,
                $_icon-font-size        : 12px,
                $_icon-font-text-hide   : true,
                $_icon-font-color       : $icon-font__color-faded
            );
            background-color: $color-white;
            border-radius: 3px 0 0 3px;

            &,
            &:hover,
            &:focus {
                border: 1px solid $border-color__base;
            }

            &:hover,
            &:focus {
                border-color: $theme-border-color-hover;
            }
        }
    }

    input[type="number"] {
        padding: 0;
        border-radius: 0;
        color: $qty-select_text-color;
        font-weight: $font-weight__medium;
        font-size: 18px;
        width: 70px;
        text-align: center;
        border: 1px solid $border-color__base;
        border-left: 0;

        &:focus {
            border-color: $theme-border-color-hover;
            border-left: 1px solid $theme-border-color-hover;

            button + & {
                border-right: 0;
            }
        }
    }

    div.mage-error {
        display: none !important; // sass-lint:disable-line no-important
    }
}

.field.qty {
    order: -1;
}

.product-item-actions {
    form {
        display: flex;
    }

    .qty-select {
        margin-right: 10px;
        padding-right: 23px;

        &__button {
            width: 24px;

            &::before,
            &::after {
                vertical-align: 1px;
            }
        }
    }
}
