._has-datepicker ~ .ui-datepicker-trigger {
    line-height: 1;
    margin-top: -2px;
}

.ui-datepicker {
    display: none;
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
}

.ui-datepicker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    line-height: 50px;
    color: #b0aead;
    background: #e9e5e3;
    margin-bottom: 10px;
}

.ui-datepicker-prev,
.ui-datepicker-next {
    width: 20px;
    height: 20px;
    text-indent: 9999px;
    border: 2px solid #b0aead;
    border-radius: 100%;
    cursor: pointer;
    overflow: hidden;
}

.ui-datepicker-prev {
    margin-left: 12px;
    margin-right: 12px;
}

.ui-datepicker-prev:after {
    transform: rotate(45deg);
    margin: -43px 0px 0px 8px;
}

.ui-datepicker-next {
    order: 2;
    margin-left: 12px;
    margin-right: 12px;
}

.ui-datepicker-next:after {
    transform: rotate(-135deg);
    margin: -43px 0px 0px 6px;
}

.ui-datepicker-prev:after,
.ui-datepicker-next:after {
    content: '';
    position: absolute;
    display: block;
    width: 4px;
    height: 4px;
    border-left: 2px solid #b0aead;
    border-bottom: 2px solid #b0aead;
}

.ui-datepicker-prev:hover,
.ui-datepicker-next:hover,
.ui-datepicker-prev:hover:after,
.ui-datepicker-next:hover:after {
    border-color: $brand__primary__color;
}

.ui-datepicker-title {
    flex: 1;
    display: flex;
    text-align: center;
    
    select {
        height: auto;
    }
}

.ui-datepicker-month {
    margin-right: 10px;
}

.ui-datepicker-calendar {
    width: 100%;
    text-align: center;
}

.ui-datepicker-calendar thead tr th span {
    display: block;
    width: 40px;
    color: $brand__primary__color;
    margin-bottom: 5px;
    font-size: 13px;
}

.ui-state-default {
    display: block;
    text-decoration: none;
    color: #b5b5b5;
    line-height: 40px;
    font-size: 12px;
}

.ui-state-default:hover {
    background: rgba(0, 0, 0, 0.02);
}

.ui-state-highlight {
    color: #8dd391;
}

.ui-state-active {
    color: $brand__primary__color;
}

.ui-datepicker-unselectable .ui-state-default {
    color: #eee;
    border: 2px solid transparent;
}

#onepage-checkout-shipping-method-additional-load {
    .title {
        @extend .abs-checkout-title;
    }
    
    .control {
        margin-bottom: 20px;
    }
}

.checkout-index-index {
    .ui-datepicker-header {
        background: $brand__primary__color;
    }
    
    .ui-datepicker-prev,
    .ui-datepicker-next {
        color: $color-white;
        border-color: $color-white;
        
        &:after {
            border-color: $color-white;
        }
    }
    
    .ui-datepicker-title {
        color: $color-white;
        font-weight: $font-weight__medium;
    }
    
    .ui-datepicker-month {
        margin: 0;
    }
    
    .ui-datepicker-calendar thead tr th span {
        font-weight: $font-weight__bold;
    }
    
    a {
        color: $color-gray19;
    }
    
    span.ui-state-default {
        color: $color-gray19;
    }
    
    a.ui-state-active {
        color: $brand__primary__color;
        font-weight: $font-weight__bold;
    }
    
    .select-label {
        margin-left: 42px;
    }
}
