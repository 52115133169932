.category-grid-widget {
    &__anchor {
        @include lib-link(
            $_link-color                   : $text__color,
            $_link-color-visited           : $text__color,
            $_link-color-hover             : $text__color,
            $_link-color-active            : $text__color
        );
        font-weight: $font-weight__medium;
    }
}

@include max-screen($screen__m) {
    .category-grid-widget {
        &__item {
            width: 45%;
        }
    }
}
