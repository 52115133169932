@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/museo-sans/500/MuseoSans-500',
    $font-weight: normal,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/museo-sans/700/MuseoSans-700',
    $font-weight: bold,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__alt,
    $font-path: '../fonts/museo-sans-rounded/900/MuseoSansRounded-900',
    $font-weight: normal,
    $font-style: normal
);


//
//  Desktop
//  _____________________________________________

.items {
    @include lib-list-reset-styles();
}

.std {
    @include lib-typography-cms-lists();
}

@for $i from 1 through 6 {
    .h#{$i} {
        @include lib-heading(h#{$i});
    }
}
