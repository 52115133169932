.std {
    table {
        margin-bottom: $indent__m;

        tr {
            border-bottom: 1px solid $border-color__base;

            > td,
            > th {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

.sidebar {
    .footer-menu__top-link {
        @include lib-heading(h3);
        margin-bottom: 0;
        display: inline-block;
    }

    .footer-menu__sub-menu-topall {
        display: none;
    }
}

.home-category-section {
    @extend .abs-margin-for-blocks-and-widgets;
}

.home-category-list-column {
    display: flex;
}

.home-category-list {
    margin-bottom: 0;
    width: 100%;
}

.cms-no-route {
    .std {
        text-align: center;
    }
}

.cms-block {
    @include lib-block(
        $padding: $block-padding $block-padding ($block-padding - $p__margin-bottom)
    );
}

.content-wrapper {
    @include lib-block();
}

.no-breadcrumbs {
    .header-bottom-bar {
        margin-bottom: 63px; // Same whitespace as page with breadcrumbs
    }
}

@include min-screen($screen__m) {
    .home-category-section {
        @include make-row();
        margin-right: -#{$gutter-width / 2};
        margin-left: -#{$gutter-width / 2};

        > div {
            @include make-col-ready();
            padding-right: #{$gutter-width / 2};
            padding-left: #{$gutter-width / 2};
        }

        .home-category-list-column {
            @include make-col($layout-column__sidebar-width-m);
        }

        .home-category-grid-column {
            @include make-col($layout-column-main__width-2-left-m);
        }
    }
}

@include min-screen($screen__l) {
    .home-category-section {
        .home-category-list-column {
            @include make-col(5.5);
        }

        .home-category-grid-column {
            @include make-col(18.5);
        }
    }
}

@include max-screen($screen__m) {
    .home-category-section {
        display: flex;
        flex-direction: column;
    }

    .home-category-list-column {
        display: none;
    }
}
