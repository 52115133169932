a.action.primary,
button {
    @include lib-css(border-radius, $button__border-radius);
}

a.action.primary {
    @include lib-link-as-button();
}

.action.primary {
    @include lib-button-primary();
}

.action.secondary {
    @include lib-button-secondary();
}

.action.tertiary {
    @include lib-button-tertiary();
}

.btn {
    @include lib-button();
    @include lib-link-as-button();

    &--alternative {
        @include lib-button-alternative();
    }

    &--primary {
        @include lib-button-primary();
    }

    &--secondary {
        @include lib-button-secondary();
    }

    &--tertiary {
        @include lib-button-tertiary();
    }

    &--dark-bg {
        @include lib-button-dark-bg();
    }

    &--sm {
        @include lib-button-s();
    }

    &--md {
        @include lib-button-m();
    }

    &--lg {
        @include lib-button-l();
    }

    &--full {
        @include lib-button-responsive();
    }

    &--as-link {
        @include lib-button-as-link();
    }

    &--rounded {
        @include lib-css(border-radius, $button__border-radius-rounded);
    }

    &--next {
        @include lib-button-icon(
            $_icon-font-content: $icon-next
        );
    }

    &--arrow-right {
        @include lib-button-icon(
            $_icon-font-content: $icon-arrow-right
        );
    }

    &--mini-cart {
        @include lib-button-icon(
            $_icon-font-content: $icon-cart,
            $_icon-font-text-hide: true,
            $_icon-font-margin: 0,
            $_icon-font-size: 24px
        );
    }

    &--cart {
        @include lib-button-icon(
            $_icon-font-content: $icon-add-to-cart,
            $_icon-font-size: 24px
        );
    }

    &--phone {
        @include lib-button-icon(
            $_icon-font-content: $icon-phone,
            $_icon-font-position: before,
            $_icon-font-margin: 0 10px 0 0,
            $_icon-font-size: 14px,
            $_icon-font-color: $brand__primary__color,
            $_icon-font-color-hover: $brand__primary__color__dark,
            $_icon-font-color-active: $brand__primary__color__dark,
        );
    }

    &--envelope {
        @include lib-button-icon(
            $_icon-font-content: $icon-envelope,
            $_icon-font-position: before,
            $_icon-font-margin: 0 10px 0 0,
            $_icon-font-size: 16px,
            $_icon-font-color: $brand__primary__color,
            $_icon-font-color-hover: $brand__primary__color__dark,
            $_icon-font-color-active: $brand__primary__color__dark,
        );
    }

    &--comments {
        @include lib-button-icon(
            $_icon-font-content: $icon-comments,
            $_icon-font-position: before,
            $_icon-font-margin: 0 10px 0 0,
            $_icon-font-size: 16px,
            $_icon-font-color: $brand__primary__color,
            $_icon-font-color-hover: $brand__primary__color__dark,
            $_icon-font-color-active: $brand__primary__color__dark,
        );
    }

    &--location {
        @include lib-button-icon(
            $_icon-font-content: $icon-location,
            $_icon-font-position: before,
            $_icon-font-margin: 0 10px 0 0,
            $_icon-font-size: 16px,
            $_icon-font-color: $brand__primary__color,
            $_icon-font-color-hover: $brand__primary__color__dark,
            $_icon-font-color-active: $brand__primary__color__dark,
        );
    }
}

.cms-link-button {
    @include lib-link-as-button();
    @include lib-button();
    @include lib-button-icon(
        $_icon-font-content: $icon-arrow-right
    );
}

@include max-screen($screen__s) {
    .btn {
        &--full-s {
            @include lib-button-responsive();
        }
    }
}

