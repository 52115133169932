.calculator-top,
.calculator-bottom {
    @include lib-block(
        $padding: 0,
        $mobile-padding: 0
    );
}

.calculator-top {
    @extend .abs-margin-for-blocks-and-widgets;

    &__left,
    &__right {
        padding: 40px;
    }

    &__left {
        border-bottom: 1px solid $border-color__base;
    }
}

.calculator-bottom {
    &__inner {
        padding: 40px;
    }
}

.calculator-results {
    padding-bottom: 0;
}

.calculator-table {
    &__button {
        @include lib-button-primary();
        @include lib-button-s();
        @include lib-link-as-button();
        white-space: nowrap;
    }

    &__tr {
        border-bottom: 1px solid $border-color__base;

        thead & {
            border-bottom-width: 2px;
        }
    }

    &__th-last {
        width: 1%;
    }

    th {
        background-color: $color-white-fog;
    }

    & > tbody > tr > th,
    & > tbody > tr > td,
    & > tfoot > tr > th,
    & > tfoot > tr > td {
        vertical-align: middle;
    }
}

.calculator-header {
    background-size: cover;
    background-position: center;
    min-height: 100px;

    &__inner {
        height: 0;
        overflow: hidden;
        padding-bottom: (173px / 901px * 100%);
    }
}

.calculator-index {
    &__item {
        display: flex;
        margin-bottom: 15px;
    }

    &__inner {
        width: 100%;
        display: flex;
        box-shadow: $block-shadow;
    }

    &__left {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: $block-padding;
        background-color: $color-white;
    }

    &__right {
        width: (200px / 577.5px * 100%);
        background-size: cover;
        background-position: center;
    }

    &__image {
        height: 0;
        overflow: hidden;
        padding-bottom: (241px / 200px * 100%);
    }

    &__title {
        @include lib-heading(h1);
    }
}

@include min-screen($screen__l) {
    .calculator-top {
        display: flex;

        &__left,
        &__right {
            box-sizing: border-box;
        }

        &__left {
            width: 50%;
            border-right: 1px solid $border-color__base;
            border-bottom: none;
        }

        &__right {
            flex: 1;
        }
    }
}

@include min-screen(850px) {
    .calculator-index {
        @include make-row();

        &__item {
            @include make-col-ready();
            @include make-col(12);
            margin-bottom: 0;
        }
    }
}

@include max-screen($screen__s) {
    .calculator-index {
        &__right {
            display: none;
        }
    }
}
