
.sidebar-container .filters {
    .filter-options-item {
        .filter-options-content {
            display: none;
        }

        &.active {
            .filter-options-content {
                display: block;
            }
        }

    }
}

.product-list-wrapper.loading {
    opacity: 0.6;
}

.autocomplete-wrapper {
    @extend .abs-search-styles;
    margin-top: 10px;
    min-height: 3.5rem;
    box-sizing: border-box;
    text-align: left;

    z-index: 1000;
    position: absolute;
    width: 100%;
    display: none;

    &.active {
        display: block;
    }

    .autocomplete {
        .item-count {
            padding: 8px 10px;
            text-align: right;
            font-size: 13px;
            line-height: 13px;
            color: #999;
        }

        ul.item-wrapper {
            list-style: none;
            margin: 0;
            padding: 0;

            .autocomplete-item {
                display: flex;
                align-items: flex-start;
                margin: 0;
                padding: 5px 1rem;
                cursor: pointer;

                &:hover {
                    background-color: #f5f5f5;
                    text-decoration: none;
                }

                img {
                    width: 40px;
                    margin-right: 1rem;
                }

                .meta {
                    flex-grow: 1;
                    overflow: hidden;

                    .title {
                        display: block;
                        margin-bottom: 0;

                        span {
                            color: #333;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .categories {
            li {
                padding-top: 5px;
                padding-bottom: 5px;

                &:hover {
                    background-color: #f5f5f5;
                }

                a {
                    .name {
                        padding-left: 10px;
                        margin: 0;
                        cursor: pointer;
                        color: #333;
                        font-size: 14px;
                        line-height: 21.98px;
                        font-weight: 400;
                    }

                    &:hover, &:visited {
                        text-decoration: none;
                        color: #333;
                    }
                }

            }
        }

        .no-results {
            display: flex;
            justify-content: center;
            padding: 8px 10px;
            flex-direction: column;
            color: #999;

            span, p {
                text-align: center;
                font-size: 1.2rem;
            }

            p {
                margin-bottom: 0;

                .suggest {
                    color: #666;

                    &:hover {
                        text-decoration: underline;
                        cursor: pointer
                    }
                }
            }
        }
    }

    .popular-search-terms {
        display: flex;
        flex-direction: column;

        span {
            padding: 8px 10px;
            text-align: right;
            font-size: 13px;
            line-height: 13px;
            color: #999;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                float: left;
                padding: 0.7rem 0 0.7rem 1rem;
                margin: 0;
                cursor: pointer;
            }
        }
    }
}

.autocomplete-loader {
    position: absolute;
    top: 10px;
    right: 40px;
    width: 25px;
    display: none;

    &.active {
        display: block;
    }
}
