$slider-bar-height: 4px;
$slider-handle-height: 16px;
$slider-handle-width: $slider-handle-height;

.mst-nav__slider {
    .mst-nav__slider-slider {
        position: relative;
        right: 0;
        top: 0;
        left: 0;
        bottom: 0;
        background: #f6f6f6;
        box-shadow: none;
        height: $slider-bar-height;
        margin: 1rem 0 2.5rem 0;
        border-radius: 2px;
        visibility: hidden;

        &.ui-slider {
            visibility: visible;
        }

        .ui-slider-handle {
            position: absolute;
            display: inline-block;
            z-index: 1;
            transform: translate3d(0, 0, 0);
            transition: background 450ms;
            border: 1px solid #979797;
            border-radius: 50%;
            background: #fff;
            width: $slider-handle-width;
            height: $slider-handle-height;
            left: 0;
            top: -($slider-handle-height / 2 - $slider-bar-height / 2);
            cursor: pointer;
            padding: 0;
            margin: 0 0 0 0;

            &.ui-slider-handle-hover {
            }

            &.ui-slider-handle-active {
            }
        }

        :nth-child(3) {
            margin-left: -1.5rem;
        }

        .ui-slider-range {
            height: 4px;
            background: $brand__primary__color;
            position: relative;
        }
    }

    .mst-nav__slider-text {
        text-align: center;
        font-weight: 600;
        margin-bottom: 1.5rem;
        margin-top: 1rem;
        font-size: 1.3rem;
    }

    .mst-nav__slider-from-to {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .mst-nav__slider-del {
            margin: 0 .5rem;
        }

        input {
            flex: 1;
        }

        button {
            @include lib-button-primary();
            display: none;
            width: 100%;
            margin-top: 10px;
            justify-content: center;
        }
    }
}

