.catalog-product-view {
    .product-info-price {
        display: flex;
        align-items: flex-end;
    }
    
    .special-price {
        margin: 0;
    }
    
    .price-final_price {
        margin-top: 20px;
    }
}

.traffic-light {
    margin-bottom: 5px;
    
    &__text {
        position: relative;
        padding-left: 35px;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 1.2;
        letter-spacing: 0.04em;
        font-weight: $font-weight__bold;
        @include lib-icon-font(
            $_icon-font-content: $icon-delivery,
            $_icon-font-size: 28px
        );
        
        &::before {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        
        &--in-stock {
            color: $accept__color;
        }
        
        &--out-of-stock {
            color: $text__color__muted;
        }
    }
}
