.header-top-bar {
    padding: 5px 0;
    border-bottom: 1px solid $theme-border-color-dark-bg;
    background-color: $brand__secondary__color;
    color: $color-white;

    a {
        @include lib-link-all(
            $_link-color: $color-white
        );
    }

    li::before {
        color: $color-white;
    }
}

.header-top-bar-inner {
    display: flex;
    justify-content: space-between;
}

.header-top-bar-usps-container {
    @extend .abs-usps-horizontal;
}

@include max-screen($screen__l) {
    .header-phonenumber {
        display: none;
    }
}

@include max-screen($screen__m) {
    .header-top-bar {
        display: none;
    }
}
