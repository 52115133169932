@import 'colors/magento';
@import 'colors/blueprint';
@import 'colors/alcomex';

//
//  Magento colors -- Don't use these
//  ---------------------------------------------

$primary__color          : $color-alcomex-grey1 !default;
$primary__color__dark    : darken($primary__color, 35%) !default;
$primary__color__darker  : darken($primary__color, 13.5%) !default;
$primary__color__lighter : lighten($primary__color, 29%) !default;
$primary__color__light   : lighten($primary__color, 45%) !default;

$secondary__color        : $color-gray91 !default;
$secondary__color__light : lighten($secondary__color, 5%) !default;

//
//  Color nesting
//  ---------------------------------------------

$brand__primary__color   : $color-alcomex-light-blue !default;
$brand__primary__color__dark : $color-alcomex-light-blue__dark !default;
$brand__primary__color__light : $color-alcomex-light-blue__light !default;

$brand__secondary__color : $color-alcomex-dark-blue !default;
$brand__secondary__color__dark : $color-alcomex-dark-blue__dark !default;
$brand__secondary__color__light : $color-alcomex-dark-blue__light !default;

$brand__tertiary__color : $color-alcomex-green !default;

$page__background-color  : $color-alcomex-grey4 !default;
$panel__background-color : $color-alcomex-grey4 !default;

$active__color           : $brand__primary__color !default;

$accept__color           : $color-blueprint-green1 !default;
$error__color            : $color-alcomex-red !default;

$white-bg__hover__color  : $color-gray-light0 !default;

$default__overlay__color : rgba(0, 0, 0, 0.6) !default;

$menu__item__hover       : $color-blueprint-light-gray5 !default;

//
//  Typography
//  ---------------------------------------------

$text__color                    : $color-alcomex-grey1 !default;
$text__color__hover             : $color-alcomex-grey2 !default;
$text__color__secondary         : $color-alcomex-grey2 !default;
$text__color__secondary__hover  : $text__color__secondary !default;

$text__color__muted             : $color-alcomex-grey3 !default;
$text__color__muted__hover      : darken($text__color__muted, 7%) !default;
$text__color__muted__dark-bg    : $color-alcomex-grey7 !default;
$text__color__muted__darker     : $color-alcomex-grey8 !default;

$heading-color                  : $brand__secondary__color !default;

//
//  Icons
//  ---------------------------------------------

$theme-icon-color-regular : $color-blueprint-dark-gray2 !default;
$theme-icon-color-light  : $color-blueprint-light-gray3 !default;

//
//  Borders
//  ---------------------------------------------

$theme-border-color      : $color-alcomex-grey5 !default;
$theme-border-color-hover : $color-alcomex-grey1 !default;
$theme-border-color-dark-bg : rgba(255, 255, 255, 0.2) !default;
$theme-border-color-dark-bg-hover : rgba(255, 255, 255, 0.5) !default;
