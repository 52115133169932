//
//  Variables
//  _____________________________________________

$active-nav-indent: 42px !default;

.panel.header {
    .topbar__list,
    .links,
    .switcher {
        display: none;
    }
}

.nav-sections {
    @include lib-css(background, $navigation__background);

    .mobile-mini-account {
        display: none;
    }

    .switcher-dropdown {
        &[aria-hidden=true] {
            display: none;
        }
    }
}

.nav-toggle {
    @include lib-icon-font(
        $_icon-font-content     : $icon-menu,
        $_icon-font-size        : 12px,
        $_icon-font-color       : $middle-bar__action__color,
        $_icon-font-color-hover : $middle-bar__action__color
    );
    display: flex;
    align-items: center;
    margin-right: 10px;
    padding: 0 10px;
    cursor: pointer;
    z-index: $z-index__overlay;
    background-color: $brand__primary__color;
    color: $color-white;
    font-weight: $font-weight__bold;
    border-radius: 3px;

    span {
        margin-left: 10px;
    }
}

.off-canvas-header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $brand__primary__color;
    color: $color-white;
    font-size: 14px;
    font-weight: $font-weight__bold;
}

.nav-toggle-mobile {
    @include lib-icon-font(
        $_icon-font-content     : $icon-remove,
        $_icon-font-size        : 19px,
        $_icon-font-line-height : 1,
        $_icon-font-color       : $color-white
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    position: absolute;
    top: 10px;
    right: 15px;
}

@include lib-main-navigation();

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .customer-name,
    .customer-welcome + .authorization-link {
        display: none;
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        @include lib-css(transition, left 0.3s, 1);
        height: 100%;
        left: -80%;
        left: calc(-1 * (100vw - #{$active-nav-indent}));
        overflow: auto;
        position: fixed;
        top: 0;
        width: 80%;
        width: calc(100vw - #{$active-nav-indent});

        .menu {
            &__list {
                display: block;
            }

            &__item {
                display: block;
            }
        }

        .switcher {
            margin: 0;

            li {
                margin: 0;
            }

            strong,
            a {
                display: flex;
                padding: 15px;
                font-size: 1.4rem;
                font-weight: $font-weight__regular;
                border-bottom: 1px solid $border-color__base;
            }

            &.switcher-language {
                border-top: 1px solid $border-color__base;
            }

            strong {
                padding-right: 27px;
            }

            .label {
                display: block;
                margin-bottom: $indent__xs;
            }
        }

        .switcher-trigger {
            strong {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content  : $icon-expand,
                    $_icon-font-size     : 16px,
                    $_icon-font-position : after,
                    $_icon-font-display  : flex,
                    $_icon-font-color    : $brand__primary__color
                );

                &::after {
                    position: absolute;
                    right: 15px;
                    top: 12px;
                }
            }

            &.active strong {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-minus,
                    $_icon-font-position : after
                );
            }
        }

        .switcher-dropdown {
            @include lib-list-reset-styles();
        }

        .section-item-content:not(#store\.menu) > ul,
        .section-item-content:not(#store\.menu) > nav > ul {
            @include lib-list-reset-styles();

            li {
                margin: 0;

                &.greet.welcome {
                    display: none;
                    border-top: 1px solid $color-gray82;
                    border-bottom: 1px solid $color-gray82;
                    font-weight: $font-weight__regular;
                    padding: $navigation-level0-item__padding;
                }

                &.customer-welcome {
                    .customer-name {
                        display: none;
                    }
                }

                > a {
                    border-top: 1px solid $color-gray82;
                }
            }

            a,
            a:hover {
                @include lib-css(text-decoration, $navigation-level0-item__text-decoration);
                display: block;
                font-weight: $font-weight__regular;
                padding: 15px;
            }

            .header.links {
                border: 0;
                list-style: none;
                padding-left: 0;
            }
        }
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            @include lib-css(transition, left 0.3s, 1);
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .page-wrapper {
            left: 80%;
            left: calc(100vw - #{$active-nav-indent});
        }

        .nav-sections {
            $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);

            @include lib-css(box-shadow, $_shadow, 1);
            left: 0;
            z-index: $z-index__off-canvas-menu;
        }

        .nav-toggle {
            &::after {
                background: rgba(0, 0, 0, $overlay__opacity);
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }

    .nav-sections-items {
        @include lib-clearfix();
        position: relative;
        z-index: 1;
        padding-bottom: 20px;
    }

    .nav-sections-item-title {
        box-sizing: border-box;
        padding: 15px;
        font-size: 14px;

        .nav-sections-item-switch {
            font-weight: $font-weight__bold;

            &:hover {
                text-decoration: none;
            }
        }
    }

    #store\.information {
        border-bottom: 1px solid $color-gray82;
    }

    .nav-sections-item-content {
        box-sizing: border-box;
        background-color: $color-white;
    }

    .offcanvas-quick-order {
        a {
            display: block;
            font-weight: $font-weight__regular;
            padding: 15px;
            font-size: 14px;
            border-top: 1px solid $color-gray82;

            &:hover {
                text-decoration: none !important;
            }
        }
    }

    @include lib-main-navigation();
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .off-canvas-header,
    .nav-toggle-mobile {
        display: none;
    }

    .header.panel > .header.links > .customer-welcome + .authorization-link {
        display: none;
    }

    .nav-toggle {
        display: none;
    }

    .nav-sections {
        @include lib-vendor-prefix-flex-shrink(0);
        @include lib-vendor-prefix-flex-basis(auto);
        @include lib-css(background, $navigation-desktop__background);
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        display: block !important;
    }

    .nav-sections-item-content > * {
        display: none;
    }

    .nav-sections-item-content {
        > .navigation {
            display: block;
        }
    }

    @include lib-main-navigation-desktop();

    .panel.header {
        .topbar__list,
        .links,
        .switcher {
            display: inline-block;
        }
    }
}
