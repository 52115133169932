@mixin mobile-footer-item($trigger: '> a', $list: '> ul', $active: '&--active') {
    #{$trigger} {
        @include lib-icon-font(
            $_icon-font-content: $icon-expand,
            $_icon-font-display: block,
            $_icon-font-size: 12px
        );
        position: relative;
        display: block;

        &::before {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
    }

    #{$active} #{$trigger} {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-minus
        );
    }
}

@include min-screen($screen__xl) {
    .page-footer {
        .top-footer {
            &__item {
                padding-right: 160px;
            }
        }
    }
}

.page-footer {
    @include lib-css(background-color, $footer__background-color);
}

.footer {
    &.content {
        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }

    .phone {
        @include lib-link-all(
            $_link-color: $color-white
        );
        font-size: 22px;
        font-weight: $font-weight__bold;
    }

    .available-from {
        font-size: 14px;
        font-weight: $font-weight__medium;
        color: $text__color__muted__darker;
    }

    h3 {
        color: $text__color__muted__dark-bg;
    }

    p {
        color: $color-white;
        font-size: 13px;
        line-height: 22px;
    }
}

.footer-menu {
    &,
    &__sub-menu-list {
        @include lib-list-reset-styles();
    }

    &__sub-menu-list {
        margin-top: 20px;
    }
}

.footer-bottom {
    border-top: 1px solid $theme-border-color-dark-bg;

    &__inner {
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.footer-columns {
    margin-bottom: 40px;
}

.footer-copyright {
    font-size: 13px;
    font-weight: $font-weight__bold;
    color: $text__color__muted__dark-bg;
}

.footer-payment-providers {
    img {
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
}

.after-footer-columns {
    .block.newsletter {
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 20px;
    }

    .footer-menu__sub-menu-list {
        display: block !important;
    }
}

.after-footer-column {
    &__inner {
        margin-top: 20px;
    }
}

.top-footer {
    display: flex;
    align-items: center;
    margin-bottom: 80px;
    background-color: $color-white;
    min-height: 170px;

    &__cart {
        .page-main {
            display: flex;
            justify-content: center;

            p {
                margin: 0;
            }
        }
    }

    &__inner {
        display: flex;
        align-items: center;
    }

    &__left {
        margin: 5px 15px 5px 0;
        width: 20%;

        span {
            @include lib-heading(h3);
            font-size: 18px;
        }
    }

    &__right {
        display: flex;
        justify-content: space-around;
        width: 80%;

        h4 {
            margin-bottom: 5px;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        padding-right: 80px;

        h4 {
            font-weight: $font-weight__bold
        }

        span {
            line-height: 22px;
        }

        a {
            color: $text__color;
            text-decoration: underline;
        }
    }

    .question,
    .account {
        position: relative;
        padding-left: 80px;

        &::before {
            position: absolute;
            left: 0;
        }
    }

    .account {
        @include lib-icon-font(
            $_icon-font-content: $icon-account,
            $_icon-font-line-height: 45px,
            $_icon-font-size: 45px,
            $_icon-font-margin: 5px 0 0 0,
            $_icon-font-color: $icon-font-color-muted
        );
    }

    .question {
        @include lib-icon-font(
            $_icon-font-content: $icon-communication,
            $_icon-font-line-height: 55px,
            $_icon-font-size: 55px,
            $_icon-font-color: $icon-font-color-muted
        );
    }
}

@include min-screen($screen__m) {
    .footer-columns {
        @include make-row();
        margin-bottom: 60px;

        .after-footer-columns {
            .footer-menu {
                display: block;

                &__top-link {
                    line-height: unset;

                    &:hover {
                        text-decoration: none;
                    }
                }

                &__sub-menu-item {
                    color: $color-white;
                }

                &__sub-menu-link {
                    line-height: 22px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        .footer-menu {
            display: flex;

            &__item {
                flex: 1;
            }

            &__top-link,
            &__sub-menu-link {
                @include lib-link-all(
                    $_link-color: $color-white
                );
            }

            &__sub-menu-list {
                .footer-menu__top-link {
                    display: none;
                }
            }

            &__sub-menu-topall {
                display: none;
            }

            &__top-link {
                @include lib-heading(h3);
                @include lib-link-all(
                    $_link-color: $text__color__muted__dark-bg
                );
            }

            &__sub-menu-item {
                margin-bottom: 10px;
                font-size: $font-size__base;
            }
        }

        .after-footer-columns,
        .footer-menu-container,
        .before-footer-columns {
            @include make-col-ready();
        }

        .after-footer-columns,
        .before-footer-columns {
            @include make-col(4.8);
        }

        .footer-menu-container {
            @include make-col(14.4);
        }
    }

    .footer-bottom {
        &__inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

    }

    .footer {
        &.content {
            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }

        &__inner-link {
            @include lib-link-all(
                $_link-color: $color-white
            );
        }
    }
}

//
//  Tablet
//  _____________________________________________

@include max-screen($screen__l) {
    .top-footer {
        &__inner {
            flex-direction: column;
            padding: 0 $layout-indent__width;
        }

        &__left {
            width: 100%;
            margin: 0 0 30px 0;
        }

        &__right {
            width: 100%;
            justify-content: flex-start;
        }

        &__item {
            padding-right: 0;

            &:not(:last-child) {
                margin-right: 30px;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .footer.content .footer-menu {
        margin-right: -$layout-indent__width;
        margin-left: -$layout-indent__width;

        &__item {
            @include mobile-footer-item();
            position: relative;
            margin: 0;

            &--active {
                li {
                    &:last-child {
                        a {
                            display: block;
                            padding: 0 0 20px 0;
                            border-bottom: 1px solid $theme-border-color-dark-bg;
                        }
                    }
                }
            }
        }

        &__inner-link {
            @include lib-link-all(
                $_link-color: $color-white
            );
        }

        &__top-link,
        &__sub-menu-item a {
            @include lib-link-all(
                $_link-color: $color-white
            );
            font-weight: $font-weight__medium;
            font-size: 14px;
            margin: 0 16px;
            border-bottom: 1px solid $theme-border-color-dark-bg;
        }

        &__top-link {
            padding: 15px 0;
        }

        &__sub-menu-item a {
            border: 0;
            padding: 0;
        }

        &__sub-menu-item {
            .footer-menu__top-link,
            .footer-menu__sub-menu-link {
                @include lib-link-all(
                    $_link-color: $color-white
                );
            }
        }

        &__sub-menu-list {
            display: none;
        }
    }

    .footer-logo {
        display: none;
    }

    .content {
        .after-footer-columns {
            .footer-menu {
                &__sub-menu-list {
                    display: block;
                    margin: 0 16px;
                }

                &__sub-menu-item {
                    color: $color-white;

                    .footer-menu__sub-menu-link {
                        line-height: 24px;
                        @include lib-link-all(
                            $_link-color: $color-white
                        );

                        &:hover {
                            color: $color-white;
                            text-decoration: none;
                        }
                    }

                    a {
                        margin: 0;
                    }
                }

                &__item {
                    padding: 15px 0;

                    &::before {
                        display: none;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }

                &__bottom {
                    line-height: 22px;
                }

                &__top-link {
                    color: $text__color__muted;
                    font-weight: $font-weight__bold;
                    font-family: $font-family-name__alt;
                    letter-spacing: 0.47px;
                    text-transform: uppercase;
                    border: 0;
                    padding: 10px 0;

                    &:hover {
                        text-decoration: none;
                    }
                }

                .phone {
                    margin-top: 15px;

                    a {
                        @include lib-link-all(
                            $_link-color: $color-white
                        );
                        font-size: 22px;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                .availability {
                    color: $text__color__muted__darker;
                    font-size: 14px;
                }
            }
        }
    }

    .footer-columns {
        margin-bottom: 30px;

        .block.newsletter {
            display: none;
        }

        .social-media {
            margin-top: 20px;
        }
    }

    .after-footer-column {
        margin-top: 20px;
    }

    .top-footer {
        margin: 0;
        padding: 35px 0;

        &__left {
            margin: 0 0 35px 0;

            span {
                font-size: 16px;
            }
        }

        &__right {
            flex-direction: column;

            .account,
            .question {
                &::before {
                    font-size: 40px;
                    left: 10px;
                    top: 10px;
                }
            }
        }

        &__item {
            padding-right: 0;

            span {
                line-height: 1.62;
            }

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }

    .footer-bottom {
        border-top: 1px solid $theme-border-color-dark-bg;

        &__inner {
            display: flex;
            flex-direction: column-reverse;
            text-align: left;
            margin: 25px auto 0 auto;

            .footer-copyright {
                font-size: 12px;
                font-weight: $font-weight__medium;
                margin: 25px 0;
            }
        }
    }
}

@include screen($screen__m, ($screen__l - 1)) {
    .before-footer-columns {
        display: none;
    }

    .footer-columns .footer-menu-container {
        @include make-col(18);
    }

    .footer-columns .after-footer-columns {
        @include make-col(6);
    }
}
