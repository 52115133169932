.mst-nav__label {
    .mst-nav__label-item {
        margin: .3rem 0 !important;
        position: relative;

        a {
            display: flex;
            align-items: center;
            padding: .5rem;

            label, input {
                cursor: pointer;
            }


            input[type="checkbox"] {
                margin: 0 1rem 0 0;
                top: 0;
            }
        }

        &._mode-link {
            input[type="checkbox"] {
                display: none;
            }
        }

        &._mode-simple_checkbox {

        }

        &._highlight {
            a {
                color: #ff5501;
            }
        }

        &._image {
            .swatch-option {
                background-color: #fff !important;
                margin-bottom: 0;
            }

            input[type="checkbox"] {
                display: none;
            }

            &._checked {
                .swatch-option {
                    outline: 2px solid #ff5501;
                    border-color: #fff;
                }
            }

            &._full-width {
                label {
                    display: none;
                }

                .swatch-option {
                    min-width: 98%;
                }
            }
        }
    }
}
