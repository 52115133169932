//
//  Buttons variables
//  _____________________________________________

//
//  Buttons
//  ---------------------------------------------

//  Font style
$button__font-family                          : $font-family__base !default;
$button__font-size                            : 14px !default;
$button__font-weight                          : $font-weight__bold !default;
$button__line-height                          : $font-size__base + 2 !default;
$button__margin                               : 0 !default;
$button__padding__y                           : 0 !default;
$button__padding__x                           : 21px !default;
$button__padding                              : $button__padding__y $button__padding__x !default; // is set up to false when buttons don't depend on side paddings or have fixed width
$button__width                                : false !default; // is set up to false when buttons depend on side paddings or to fixed value when needed
$button__height                               : 47px !default;

//  Display settings
$button__display                              : inline-flex !default;
$button__cursor                               : pointer !default;
$button__border-radius                        : 3px !default;
$button__border-radius-rounded                : 3px !default;

$button__disabled__opacity                    : 0.5 !default;

//  Default                                   = secondary button
$button__color                                : $brand__primary__color !default;
$button__background                           : $color-white !default;
$button__border                               : 1px solid $theme-border-color !default;
$button__border__bottom__width                : false !default;
$button__border__bottom__color                : darken($theme-border-color, 10%) !default;
$button__gradient-color-start                 : false !default;
$button__gradient-color-end                   : false !default;

$button__hover__color                         : $brand__primary__color__dark !default;
$button__hover__background                    : false !default;
$button__hover__border                        : 1px solid $theme-border-color-hover !default;
$button__hover__border__bottom__width         : $button__border__bottom__width !default;
$button__hover__border__bottom__color         : $button__border__bottom__color !default;
$button__hover__gradient-color-start          : false !default;
$button__hover__gradient-color-end            : false !default;

$button__active__color                        : $brand__primary__color__dark !default;
$button__active__background                   : $button__hover__background !default;
$button__active__border                       : 1px solid $theme-border-color-hover !default;
$button__active__border__bottom__width        : $button__border__bottom__width !default;
$button__active__border__bottom__color        : $button__border__bottom__color !default;
$button__active__gradient-color-start         : false !default;
$button__active__gradient-color-end           : false !default;

//  Alternative button
$button-alternative__line-height                  : false !default;
$button-alternative__width                        : false !default;
$button-alternative__margin                       : false !default;
$button-alternative__padding                      : $button__padding !default;
$button-alternative__gradient                     : false !default;
$button-alternative__gradient-direction           : false !default;

$button-alternative__background                   : false !default;
$button-alternative__border                       : 1px solid $theme-border-color !default;
$button-alternative__border__bottom__width        : false !default;
$button-alternative__border__bottom__color        : false !default;
$button-alternative__color                        : $text__color__secondary !default;
$button-alternative__gradient-color-start         : false !default;
$button-alternative__gradient-color-end           : false !default;

$button-alternative__hover__background            : false !default;
$button-alternative__hover__border                : 1px solid $theme-border-color-hover !default;
$button-alternative__hover__border__bottom__width : false !default;
$button-alternative__hover__border__bottom__color : false !default;
$button-alternative__hover__color                 : $button-alternative__color !default;
$button-alternative__hover__gradient-color-start  : false !default;
$button-alternative__hover__gradient-color-end    : false !default;

$button-alternative__active__background           : false !default;
$button-alternative__active__border               : 1px solid $theme-border-color-hover !default;
$button-alternative__active__border__bottom__width : false !default;
$button-alternative__active__border__bottom__color : false !default;
$button-alternative__active__color                : $button-alternative__color !default;
$button-alternative__active__gradient-color-start : false !default;
$button-alternative__active__gradient-color-end   : false !default;

//  Primary button
$button-primary__line-height                  : false !default;
$button-primary__width                        : false !default;
$button-primary__margin                       : false !default;
$button-primary__padding                      : $button__padding !default;
$button-primary__gradient                     : false !default;
$button-primary__gradient-direction           : false !default;

$button-primary__background                   : $brand__primary__color !default;
$button-primary__border                       : 1px solid $button-primary__background !default;
$button-primary__border__bottom__width        : false !default;
$button-primary__border__bottom__color        : darken($brand__primary__color, 15%) !default;
$button-primary__color                        : $color-white !default;
$button-primary__gradient-color-start         : false !default;
$button-primary__gradient-color-end           : false !default;

$button-primary__hover__background            : $brand__primary__color__dark !default;
$button-primary__hover__border                : 1px solid $button-primary__hover__background !default;
$button-primary__hover__border__bottom__width : $button-primary__border__bottom__width !default;
$button-primary__hover__border__bottom__color : $button-primary__border__bottom__color !default;
$button-primary__hover__color                 : $button-primary__color !default;
$button-primary__hover__gradient-color-start  : false !default;
$button-primary__hover__gradient-color-end    : false !default;

$button-primary__active__background           : $button-primary__hover__background !default;
$button-primary__active__border               : 1px solid $button-primary__active__background !default;
$button-primary__active__border__bottom__width : $button-primary__border__bottom__width !default;
$button-primary__active__border__bottom__color : $button-primary__border__bottom__color !default;
$button-primary__active__color                : $button-primary__color !default;
$button-primary__active__gradient-color-start : false !default;
$button-primary__active__gradient-color-end   : false !default;

//  Secondary button
$button-secondary__line-height                  : false !default;
$button-secondary__width                        : false !default;
$button-secondary__margin                       : false !default;
$button-secondary__padding                      : $button__padding !default;
$button-secondary__gradient                     : false !default;
$button-secondary__gradient-direction           : false !default;

$button-secondary__background                   : $brand__secondary__color !default;
$button-secondary__border                       : 1px solid $button-secondary__background !default;
$button-secondary__border__bottom__width        : false !default;
$button-secondary__border__bottom__color        : darken($brand__secondary__color, 15%) !default;
$button-secondary__color                        : $color-white !default;
$button-secondary__gradient-color-start         : false !default;
$button-secondary__gradient-color-end           : false !default;

$button-secondary__hover__background            : $brand__secondary__color__dark !default;
$button-secondary__hover__border                : 1px solid $button-secondary__hover__background !default;
$button-secondary__hover__border__bottom__width : $button-secondary__border__bottom__width !default;
$button-secondary__hover__border__bottom__color : $button-secondary__border__bottom__color !default;
$button-secondary__hover__color                 : $color-white !default;
$button-secondary__hover__gradient-color-start  : false !default;
$button-secondary__hover__gradient-color-end    : false !default;

$button-secondary__active__background           : $button-secondary__hover__background !default;
$button-secondary__active__border               : 1px solid $button-secondary__active__background !default;
$button-secondary__active__border__bottom__width : $button-secondary__border__bottom__width !default;
$button-secondary__active__border__bottom__color : $button-secondary__border__bottom__color !default;
$button-secondary__active__color                : $color-white !default;
$button-secondary__active__gradient-color-start : false !default;
$button-secondary__active__gradient-color-end   : false !default;

//  Tertiary button
$button-tertiary__line-height                  : false !default;
$button-tertiary__width                        : false !default;
$button-tertiary__margin                       : false !default;
$button-tertiary__padding                      : $button__padding !default;
$button-tertiary__gradient                     : false !default;
$button-tertiary__gradient-direction           : false !default;

$button-tertiary__background                   : $brand__tertiary__color !default;
$button-tertiary__border                       : 1px solid $button-tertiary__background !default;
$button-tertiary__border__bottom__width        : false !default;
$button-tertiary__border__bottom__color        : darken($brand__tertiary__color, 15%) !default;
$button-tertiary__color                        : $color-white !default;
$button-tertiary__gradient-color-start         : false !default;
$button-tertiary__gradient-color-end           : false !default;

$button-tertiary__hover__background            : darken($brand__tertiary__color, 10%) !default;
$button-tertiary__hover__border                : 1px solid $button-tertiary__hover__background !default;
$button-tertiary__hover__border__bottom__width : $button-tertiary__border__bottom__width !default;
$button-tertiary__hover__border__bottom__color : $button-tertiary__border__bottom__color !default;
$button-tertiary__hover__color                 : $button-tertiary__color !default;
$button-tertiary__hover__gradient-color-start  : false !default;
$button-tertiary__hover__gradient-color-end    : false !default;

$button-tertiary__active__background           : $button-tertiary__hover__background !default;
$button-tertiary__active__border               : 1px solid $button-tertiary__active__background !default;
$button-tertiary__active__border__bottom__width : $button-tertiary__border__bottom__width !default;
$button-tertiary__active__border__bottom__color : $button-tertiary__border__bottom__color !default;
$button-tertiary__active__color                : $button-tertiary__color !default;
$button-tertiary__active__gradient-color-start : false !default;
$button-tertiary__active__gradient-color-end   : false !default;

//  Button on dark backgrounds
$button-dark-bg__line-height                  : false !default;
$button-dark-bg__width                        : false !default;
$button-dark-bg__margin                       : false !default;
$button-dark-bg__padding                      : $button__padding !default;
$button-dark-bg__gradient                     : false !default;
$button-dark-bg__gradient-direction           : false !default;

$button-dark-bg__background                   : transparent !default;
$button-dark-bg__border                       : 1px solid $theme-border-color-dark-bg !default;
$button-dark-bg__border__bottom__width        : false !default;
$button-dark-bg__border__bottom__color        : false !default;
$button-dark-bg__color                        : $color-white !default;
$button-dark-bg__gradient-color-start         : false !default;
$button-dark-bg__gradient-color-end           : false !default;

$button-dark-bg__hover__background            : transparent !default;
$button-dark-bg__hover__border                : 1px solid $theme-border-color-dark-bg-hover !default;
$button-dark-bg__hover__border__bottom__width : false !default;
$button-dark-bg__hover__border__bottom__color : false !default;
$button-dark-bg__hover__color                 : $color-white !default;
$button-dark-bg__hover__gradient-color-start  : false !default;
$button-dark-bg__hover__gradient-color-end    : false !default;

$button-dark-bg__active__background           : transparent !default;
$button-dark-bg__active__border               : 1px solid $theme-border-color-dark-bg-hover !default;
$button-dark-bg__active__border__bottom__width : false !default;
$button-dark-bg__active__border__bottom__color : false !default;
$button-dark-bg__active__color                : $color-white !default;
$button-dark-bg__active__gradient-color-start : false !default;
$button-dark-bg__active__gradient-color-end   : false !default;

//  Gradient button
$button__gradient                             : false !default; // [true|false] - button has a gradient background
$button__gradient-direction                   : false !default; // button gradient direction if button has a gradient background

//  Button with icon
$button-icon__use                             : false !default;
$button-icon__content                         : $icon-settings !default;
$button-icon__font                            : $icon-font !default;
$button-icon__font-size                       : 11px !default;
$button-icon__line-height                     : $button-icon__font-size !default;
$button-icon__color                           : inherit !default;
$button-icon__margin                          : 0 0 0 14px !default;
$button-icon__vertical-align                  : middle !default;
$button-icon__position                        : after !default;
$button-icon__text-hide                       : false !default;

$button-icon__hover__font-color               : inherit !default;
$button-icon__active__font-color              : inherit !default;

//  Large button
$button__font-size__l                         : 15px !default;
$button__height__l                            : 56px !default;
$button__line-height__l                       : $font-size__l + 4 !default;
$button__padding__l                           : 10px 22px !default;
$button__padding__l__desktop                  : 13px 27px !default;
$button__border__width__l                     : 0 0 4px 0 !default;
$button__border__bottom__width__l             : false !default;

//  Medium button
$button__font-size__m                         : 15px !default;
$button__line-height__m                       : $button__font-size__m + 4 !default;
$button__padding__m                           : 10px 22px !default;
$button__padding__m__desktop                  : 16.5px 25px !default;
$button__border__width__m                     : 0 0 4px 0 !default;
$button__border__bottom__width__m             : false !default;

//  Small button
$button__font-size__s                         : 13px !default;
$button__height__s                            : 35px !default;
$button__line-height__s                       : $button__font-size__s + 1 !default;
$button__padding__s                           : 9.5px 20px !default;
$button__border__bottom__width__s             : false !default;
