.about-section {
    @extend .abs-margin-for-blocks-and-widgets;
    display: flex;
    box-shadow: $block-shadow;

    &__left {
        @include lib-block();
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: none;
    }

    &__right {
        background-size: cover;
        background-position: center center;
    }

    &__image {
        height: 0;
        overflow: hidden;
        padding-bottom: (394px / 700px * 100%);
    }

    &__title {
        margin-bottom: 20px;
        font-size: 27px;
    }

    &__button {
        @include lib-button-icon(
            $_icon-font-content: $icon-arrow-right,
            $_icon-font-size: 13px,
            $_icon-font-line-height: 13px
        );
        white-space: nowrap;
    }
}

@include min-screen($screen__m) {
    .about-section {
        &__left {
            padding: 50px;
        }

        &__right {
            width: 60%;
            max-width: 700px;
        }
    }
}

@include max-screen($screen__m) {
    .about-section {
        flex-direction: column;

        &__right {
            order: -1;
        }
    }
}
