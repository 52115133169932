.faq-categories {
    &__title {
        @include lib-heading(h4);
        margin-top: 15px;
        margin-bottom: 0;
    }

    &__anchor {
        @include lib-block($padding: 30px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:hover {
            text-decoration: none;
        }
    }
}

@include min-screen($screen__m) {
    .faq-categories {
        @include make-row();
        margin-bottom: 40px - 16px;

        &__column {
            @include make-col-ready();
            @include make-col(6);
            margin-bottom: 16px;
        }

        &__anchor {
            background-color: $color-white;
        }
    }
}

@include max-screen($screen__m) {
    .faq-categories {
        display: flex;
        flex-wrap: wrap;
        margin-left: -$layout-indent__width;
        margin-right: -$layout-indent__width;
        border-top: 1px solid $border-color__base;

        &__column {
            box-sizing: border-box;
            width: 50%;
            border-bottom: 1px solid $border-color__base;

            &:nth-child(odd) {
                border-right: 1px solid $border-color__base;
            }
        }
    }
}
