.faq-list {
    @extend .abs-margin-for-blocks-and-widgets;

    &__item {
        @include lib-block(
            $padding: 0
        );
        position: relative;
        margin-bottom: 30px;
    }

    &__trigger,
    &__title {
        @include lib-heading(h4);
        position: relative;
        margin-bottom: 0;

        &::before {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
        }
    }

    &__trigger {
        @include lib-icon-font(
            $_icon-font-content: $icon-expand,
            $_icon-font-display: block,
            $_icon-font-color: $brand__primary__color,
            $_icon-font-size: 20px
        );
        padding: 20px 30px;
        cursor: pointer;

        &[aria-expanded=true] {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-minus
            );
        }
    }

    &__title {
        @include lib-icon-font(
            $_icon-font-content: $icon-next,
            $_icon-font-display: block,
            $_icon-font-color: $brand__primary__color,
            $_icon-font-size: 20px
        );

        a {
            @include lib-link(
                $_link-color                   : $brand__secondary__color,
                $_link-text-decoration         : none,
                $_link-color-visited           : $brand__secondary__color,
                $_link-text-decoration-visited : none,
                $_link-color-hover             : $brand__secondary__color,
                $_link-text-decoration-hover   : none,
                $_link-color-active            : $brand__secondary__color,
                $_link-text-decoration-active  : none
            );
            display: block;
            padding: 20px 30px;
        }
    }

    &__description {
        display: none;
    }

    &__inner {
        padding: 0 30px 20px;
    }

    &__feedback-text {
        margin-right: 15px;
    }

    &__btn {
        @include lib-button-as-link();

        &--dislike {
            @include lib-icon-font(
                $_icon-font-content: $icon-thumbs-down,
                $_icon-font-size: 20px,
                $_icon-font-margin: 0 4px 0 0
            );
        }

        &--like {
            @include lib-icon-font(
                $_icon-font-content: $icon-thumbs-up,
                $_icon-font-size: 20px,
                $_icon-font-margin: -3px 4px 0 0
            );
            margin-right: 10px;
        }
    }
}

.faq-results-list {
    .faq-list {
        &__inner {
            p:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.block-faq-portal-top {
    max-width: 700px;
}

.faq-search-form {
    margin-bottom: 40px;
}

@include max-screen($screen__m) {
    .faq-list {
        &__feedback-text {
            display: block;
            width: 100%;
            margin: 0;
        }
    }
}
