.block.newsletter {
    margin-bottom: 0;

    .form.subscribe {
        display: flex;
        width: 100%;
    }

    .field.newsletter {
        flex: 1;
        display: flex;
        margin: 0;

        .control {
            display: flex;
            width: 100%;
        }

        input[type="email"] {
            height: auto;
            border-radius: 0;
        }
    }

    .fieldset {
        margin: 0;
        padding: 0;
        vertical-align: top;
    }

    .title {
        display: none;
    }

    .label {
        @extend .abs-visually-hidden;
    }

    .action.primary {
        @include lib-button-m();
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

}
