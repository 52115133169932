.category-intro {
    @include lib-block();
    margin-bottom: 30px;

    &__table {
        @include lib-table(
            $_cell-padding-vertical   : 0,
            $_cell-padding-horizontal : 0,
            $_table-margin-bottom     : 15px
        );
        font-size: 14px;
        line-height: 1.2;

        &-td {
            width: 100%;
        }

        & > tbody > tr > td {
            padding-left: 40px;
            padding-bottom: 7px;

            &.category-intro__tooltip {
                padding-left: 10px;
            }
        }
    }

    &__table-th {
        display: flex;
        align-items: center;
    }

    .legend-tooltip {
        &__icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: $color-blueprint-dark-gray2;
            margin-left: 10px;
            padding: 5px;
            height: 6px;
            width: 6px;
            font-size: 11px;
            color: $color-white;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.measurement-units {
    p {
        margin-bottom: 0;
    }
}

@include min-screen($screen__m) {
    .category-intro {
        &__row {
            display: flex;
        }

        &__left {
            box-sizing: border-box;
            max-width: 50%;
            padding-right: 20px;
            flex: 0 0 auto;
        }

        &__right {
            margin-left: 20px;
        }
    }
}
