.contact-form-block {
    @extend .abs-margin-for-blocks-and-widgets;
}

.contact-info {
    @include lib-block();

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        &.variable-link {
            @include lib-link-all(
                $_link-color: $text__color
            );
        }

        &:not([class]) {
            @include lib-icon-font(
                $_icon-font-content: $icon-next,
                $_icon-font-position: after,
                $_icon-font-margin: 0 0 0 7px
            );
        }
    }
}
