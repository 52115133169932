.home-category-grid-button {
    @include lib-button-as-link();
    @include lib-link(
        $_link-color                   : $brand__primary__color,
        $_link-text-decoration         : underline,
        $_link-color-visited           : $brand__primary__color,
        $_link-text-decoration-visited : underline,
        $_link-color-hover             : $brand__primary__color,
        $_link-text-decoration-hover   : underline,
        $_link-color-active            : $brand__primary__color,
        $_link-text-decoration-active  : underline,
    );
    margin-top: 10px;
}

@include max-screen($screen__m) {
    .home-category-grid-column {
        order: -1;
    }

    .home-category-grid {
        &__bottom {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__item:not(:last-child) {
            margin-bottom: 10px;
        }

        .grid-image {
            display: none;
        }

        &__image {
            display: block;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        &__mobile {
            @include lib-icon-font(
                $_icon-font-content: $icon-next,
                $_icon-font-display: block,
                $_icon-font-position: after,
                $_icon-font-color: $color-white,
                $_icon-font-size: 18px
            );
            position: relative;
            display: flex;
            align-items: center;
            padding-right: 30px;
            background-color: $color-white;

            &::after {
                position: absolute;
                top: 50%;
                right: 18px;
                transform: translateY(-50%);
            }

            .top & {
                height: 120px;
                font-size: 17px;
                font-family: $font-family-name__alt;
                color: $color-white;
            }

            .bottom & {
                height: 68px;
                background-color: $color-white;

                &::after {
                    color: $brand__primary__color;
                }
            }
        }

        &__mobile-image {
            display: flex;
            align-items: center;

            img {
                display: block;
            }
        }

        &__mobile-content {
            flex: 1;

            .bottom & {
                @include lib-link(
                    $_link-color                   : $brand__secondary__color,
                    $_link-color-visited           : $brand__secondary__color,
                    $_link-color-hover             : $brand__secondary__color,
                    $_link-text-decoration-hover   : none,
                    $_link-color-active            : $brand__secondary__color,
                    $_link-text-decoration-active  : none
                );
                flex: 1;
                position: relative;
                display: block;
                padding: 0 15px;
                font-size: 14px;
                font-family: $font-family-name__alt;
            }
        }
    }
}

@include min-screen($screen__m) {
    .home-category-grid-button {
        display: none;
    }

    .home-category-grid {
        &__row {
            margin-right: -#{$grid-gutter-width / 2};
            margin-left: -#{$grid-gutter-width / 2};
        }

        .grid-image {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }

        &__image {
            display: block;
            overflow: hidden;
            height: 0;
            background-size: cover;
            background-position: center center;

            &.top {
                //padding-bottom: (381px / 440px * 100%);
                height: 381px;
                margin-bottom: $grid-gutter-width;
            }

            &.bottom {
                //padding-bottom: (315px / 289px * 100%);
                height: 351px;
            }
        }

        &__mobile {
            display: none;
        }
    }
}
