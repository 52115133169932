$color-alcomex-dark-blue                : #061d43 !default;
$color-alcomex-dark-blue__dark          : #031129 !default;
$color-alcomex-dark-blue__light         : #0e326e !default;
$color-alcomex-opacity-blue             : #1f3456 !default

$color-alcomex-light-blue               : #269adb !default;
$color-alcomex-light-blue__dark         : #0981c4 !default;
$color-alcomex-light-blue__light        : #4ab9f7 !default;

$color-alcomex-grey1                    : #666666 !default
$color-alcomex-grey2                    : #333333 !default
$color-alcomex-grey3                    : #7c7c7c !default
$color-alcomex-grey4                    : #ededed !default
$color-alcomex-grey5                    : #d7d9dd !default
$color-alcomex-grey6                    : #cfd1d5 !default
$color-alcomex-grey7                    : #8f8c8f !default
$color-alcomex-grey8                    : #818187 !default
$color-alcomex-grey9                    : #999999 !default
$color-alcomex-grey10                   : #7d7d7d !default;
$color-alcomex-grey11                   : #a3aab7 !default;
$color-alcomex-grey12                   : #f6f6f6 !default;

$color-alcomex-green                    : #6eb230 !default;

$color-alcomex-orange                   : #ff7201 !default;

$color-alcomex-red                      : #da2e2a !default;
