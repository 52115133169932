.header-chevron {
    @include lib-icon-font(
        $_icon-font-content         : $icon-down,
        $_icon-font-position        : after,
        $_icon-font-margin          : 0 0 0 7px,
        $_icon-font-size            : 17px,
        $_icon-font-color           : false ,
        $_icon-font-color-hover     : false,
        $_icon-font-color-active    : false,
        $_icon-font-display         : false
    );

    &.active {
        @include lib-icon-font-symbol(
            $_icon-font-content  : $icon-up,
            $_icon-font-position : after
        );
    }
}

.header-middle-bar-wrapper {
    @include lib-css(background-color, $middle-bar__background-color);
}

.header-middle-bar {
    &__inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &__logo {
        display: flex;
        align-items: center;
        max-width: 45%;
    }

    &__header-icons {
        display: flex;
        position: relative;
    }

    &__search {
        width: 100%;
    }

    &__bottom-wrapper {
        display: flex;
        align-items: stretch;
    }

    &__user.in-header {
        @include lib-list-reset-styles();

        > li {
            margin: 0;

            > a {
                font-size: 14px;
                font-weight: $font-weight__bold;
            }
        }
    }

    .customer-name {
        @extend .header-chevron;
    }

    .action.showcart {
        position: relative;
        white-space: nowrap;

        .counter.qty {
            @extend .abs-number-badge;

            &.empty {
                display: none;
            }

            .loader {
                > img {
                    @include lib-css(max-width, $minicart-qty__height);
                }
            }
        }

        .counter-label {
            @extend .abs-visually-hidden;
        }
    }

    .header-action {
        // .header-action is inside .header-middle-bar because we don't want this in the off-canvas menu
        @include lib-link-all(
            $_link-color: $middle-bar__action__color
        );
        @include lib-css(border, $middle-bar__action__border);
        box-sizing: border-box;
        z-index: 105;
        display: flex;
        align-items: center;
        height: $button__height;
        padding: 0 14px;
        border-radius: 3px;
        font-weight: $font-weight__bold;

        &:hover {
            @include lib-css(background-color, $middle-bar__action__bg-color-hover);
            @include lib-css(border-color, $middle-bar__action__border-color-hover);
            text-decoration: none;
        }

        &.active {
            @include lib-css(border-color, $middle-bar__action__border-color-active);
            background-color: $color-white;
            color: $middle-bar__action__color-active;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            &::after {
                color: $icon-font__color-faded;
            }
        }
    }

    .header-button {
        // .header-button is inside .header-middle-bar because we don't want this in the off-canvas menu
        @include min-screen($screen__m) {
            @include lib-link-all(
                $_link-color: $middle-bar__button__color
            );
            @include lib-css(border, $middle-bar__button__border);
            @include lib-css(background-color, $middle-bar__button__bg-color);
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            height: 45px;
            padding: 0 16px;
            border-radius: 3px;

            &:hover {
                @include lib-css(background-color, $middle-bar__button__bg-color-hover);
                text-decoration: none;
            }

            &.active {
                @include lib-css(border-color, $middle-bar__button__border-color-active);
                background-color: $color-white;
                color: $middle-bar__button__color-active;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
}

.mobile-mini-account {
    &__anchor {
        @include lib-icon-font(
            $_icon-font-content: $icon-account,
            $_icon-font-text-hide: true,
            $_icon-font-color: $color-white,
            $_icon-font-size: 20px,
            $_icon-font-margin: 0 15px 0 0,
            $_icon-font-line-height: 1
        );
    }
}

.mini-quick-order {
    .header-action {
        @include lib-icon-font(
            $_icon-font-content: $icon-quick-order,
            $_icon-font-display: false,
            $_icon-font-text-hide: true,
            $_icon-font-size: 17px
        );
    }
}

@include min-screen($screen__m) {
    .header-middle-bar {
        &__header-icons {
            .header-action {
                margin-right: 10px;
            }
        }

        &__search {
            padding-right: 15px;
            padding-left: 15px;
        }

        &__bottom-wrapper {
            flex: 1;
        }

        &__inner {
            @include make-row();
            margin-right: -#{$gutter-width / 2};
            margin-left: -#{$gutter-width / 2};
        }

        &__logo {
            @include make-col-ready();
            @include lib-layout-sidebar();
            padding-right: #{$gutter-width / 2};
            padding-left: #{$gutter-width / 2};
        }

        .header-icons {
            margin-right: #{$gutter-width / 2};
        }
    }

    .mobile-mini-account {
        display: none;
    }
}

@include min-screen($screen__l) {
    .header-middle-bar {
        &__user.in-header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        &__search {
            padding-right: #{$gutter-width / 2};
            padding-left: #{$gutter-width / 2};
        }
    }
}

@include max-screen($screen__m) {
    .header-middle-bar {
        padding: 0;

        &__inner {
            padding: 10px;
        }

        &__logo {
            img {
                max-height: 47px;
            }
        }

        &__search {
            input {
                height: 37px;
            }
        }

        &__bottom-wrapper {
            width: 100%;
            order: 4;
            margin-top: 10px;
        }

        &__header-icons {
            flex: 1;
        }

        &__header-icons {
            justify-content: flex-end;
            z-index: 1;
        }

        .login-wrapper,
        .customer-welcome {
            display: none;
        }
    }

    .mini-quick-order {
        display: none;
    }
}
