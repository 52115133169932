.social-media {
    display: flex;
    flex-wrap: wrap;

    &__anchor {
        @include lib-icon-text-hide();
        @include lib-link(
            $_link-color                   : $color-white,
            $_link-color-visited           : $color-white,
            $_link-color-hover             : $color-white,
            $_link-text-decoration-hover   : none,
            $_link-color-active            : $color-white,
            $_link-text-decoration-active  : none
        );
        @include lib-icon-font(
            $_icon-font-content: false,
            $_icon-font-size: 32px
        );
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid $theme-border-color-dark-bg;

        &:hover {
            border-color: $theme-border-color-dark-bg-hover;
        }

        &:not(:last-child) {
            margin-right: 10px;
        }

        &.facebook {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-facebook
            );
        }

        &.twitter {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-twitter
            );
        }

        &.instagram {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-instagram
            );
        }

        &.linkedin {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-linkedin
            );
        }

        &.pinterest {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-pinterest
            );
        }

        &.youtube {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-youtube
            );
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .social-media {
        display: none;
    }
}
