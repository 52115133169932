.block {
    @extend .abs-margin-for-blocks-and-widgets;
    
    .title {
        @extend .abs-block-title;
    }
    
    .box-actions {
        margin-top: $indent__xs;
    }
}

.sidebar {
    .block {
        margin: 0;
        
        .title {
            @extend .abs-block-title-sidebar;
        }
    }
}

.modals-wrapper {
    .block {
        margin: 0;
        
        .title {
            margin-bottom: 20px;
        }
    }
}

@include max-screen($screen__m) {
    .account {
        .sidebar {
            .block {
                .title {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
